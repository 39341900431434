import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';
import {setFavouriteCompetitionsObject} from '../../../../Redux/SportSlice';
import {getFavouriteCompetitionsObject} from '../../../../Redux/SportSlice/SportSelectors';

import {useSocket} from '../../../../Hooks';

import {
  MATCH_FILTERS,
  FILTERS_LIVE_NAME,
  FILTERS_TODAY_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_UPCOMING_SEVEN_DAYS_STATUS,
} from '../../../../Constants/MatchFilters';
import {GET_FIXED_COMPETITIONS} from '../../../../Constants/Socket';
import {TOP_LEAGUES_COMPETITIONS_DATA} from '../../../../Constants/TopLeaguesCompetitionsData';

import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/whiteStar.svg';
import {ReactComponent as TopLeagueIcon} from '../../../../Assets/Icons/Globals/topLeague.svg';

import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';

const SideBarTopLeagues = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();
  const {getCompetitions, getFixedCompetitions, setFavouriteCompetition} =
    useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const [fixedCompetitions, setFixedCompetitions] = useState([]);
  const [isCompetitionsLoading, setIsCompetitionsLoading] = useState(false);

  const showTopLeagues = useMemo(
    () =>
      fixedCompetitions?.length > 0 &&
      (gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME ||
        gameFilterParam === FILTERS_LIVE_NAME ||
        gameFilterParam === FILTERS_TODAY_NAME),
    [fixedCompetitions?.length, gameFilterParam],
  );

  const selectedCompetitionsIds = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.map?.(item => +item) || [];
  }, [regionParam]);

  const onFavouriteSelect = useCallback(
    competitionId => {
      const newFavouriteCompetitionsObject = {...favouriteCompetitionsObject};
      if (
        Object.keys(newFavouriteCompetitionsObject || {})?.includes(
          `${competitionId}`,
        )
      ) {
        delete newFavouriteCompetitionsObject[`${competitionId}`];
      } else {
        newFavouriteCompetitionsObject[`${competitionId}`] = {};
      }
      dispatch(setFavouriteCompetitionsObject(newFavouriteCompetitionsObject));
      setFavouriteCompetition({idsObject: newFavouriteCompetitionsObject});
    },
    [dispatch, setFavouriteCompetition, favouriteCompetitionsObject],
  );

  const onCompetitionClick = useCallback(
    (regionAlias, competitionId) => {
      const splitArray = !!regionParam ? (regionParam || '')?.split?.('-') : [];

      const findIndex = splitArray.findIndex(item =>
        item?.includes(`${regionAlias}`),
      );

      if (!splitArray?.length) {
        splitArray.push(`${regionAlias}_${competitionId}`);
      } else if (findIndex > -1) {
        const competitions = splitArray?.[findIndex]?.split?.('_');
        const foundCompetitionIndex = competitions.indexOf(`${competitionId}`);
        if (foundCompetitionIndex > -1) {
          competitions.splice(foundCompetitionIndex, 1);
        } else {
          competitions.push(`${competitionId}`);
        }
        if (competitions?.length === 1) {
          splitArray?.splice(findIndex, 1);
        } else {
          splitArray[findIndex] = competitions.join('_');
        }
      } else {
        splitArray.push(`${regionAlias}_${competitionId}`);
      }

      if (!!splitArray?.length) {
        getParam.set('region', splitArray.join('-'));
      } else {
        getParam.delete('region');
      }
      setGetParam(getParam);
    },
    [getParam, regionParam, setGetParam],
  );

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case GET_FIXED_COMPETITIONS:
        const fixedCompetitionsArray = [];
        const regionArray = Object.values(data?.data?.data?.region || {});
        for (let i = 0; i < regionArray?.length; i++) {
          const competitionArray = Object.values(
            regionArray?.[i]?.competition || {},
          );
          for (let j = 0; j < competitionArray?.length; j++) {
            fixedCompetitionsArray?.push({
              regionId: regionArray?.[i]?.id,
              title: competitionArray?.[j]?.name,
              regionAlias: regionArray?.[i]?.alias,
              competitionId: competitionArray?.[j]?.id,
              firstGameStartTime: Object.values(
                competitionArray?.[j]?.game || {},
              )?.sort((a, b) => a?.start_ts - b?.start_ts)?.[0]?.start_ts,
              logo: `https://statistics.bcapps.site/images/c/m/0/${competitionArray?.[j]?.id}.png`,
            });
          }
        }
        const sortedFixedCompetitionsArray = fixedCompetitionsArray
          ?.sort((a, b) => a?.firstGameStartTime - b?.firstGameStartTime)
          ?.slice?.(0, 8);
        // setFixedCompetitions(
        //   sortedFixedCompetitionsArray ||
        //     FixedCompetitionsFilters?.[sportParam],
        // );
        setFixedCompetitions(sortedFixedCompetitionsArray);
        setTimeout(() => {
          setIsCompetitionsLoading(false);
        }, 400);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    setIsCompetitionsLoading(true);
    if (TOP_LEAGUES_COMPETITIONS_DATA?.[sportParam]) {
      getFixedCompetitions({
        activeSportAlias: sportParam,
        competitionsIdsArray: TOP_LEAGUES_COMPETITIONS_DATA?.[sportParam],
        gameFilters:
          gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME
            ? FILTERS_UPCOMING_SEVEN_DAYS_STATUS
            : MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    } else if (gameFilterParam !== FILTERS_LIVE_NAME) {
      // setFixedCompetitions(FixedCompetitionsFilters?.[sportParam]);
      setTimeout(() => {
        setIsCompetitionsLoading(false);
      }, 400);
    }
  }, [sportParam, gameFilterParam, getFixedCompetitions]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`blueBackground rounded-borders column gap-10 ${
        !showTopLeagues && 'hidden'
      }`}>
      <div className="row items-center justify-between">
        <div className="row items-center gap-10">
          <TopLeagueIcon fill="white" />
          <span className="bold-600 whiteText font-15">
            {t('topLeagues')?.toUpperCase()}
          </span>
        </div>
      </div>
      <div>
        <div className="column gap-5">
          {isCompetitionsLoading ? (
            <RegionSkeletons />
          ) : !!fixedCompetitions?.length ? (
            fixedCompetitions?.map(fixedCompetitionItem => (
              <div
                key={fixedCompetitionItem?.competitionId}
                onClick={e => {
                  e?.preventDefault?.();
                  e?.stopPropagation?.();
                  onCompetitionClick(
                    fixedCompetitionItem?.regionAlias,
                    fixedCompetitionItem?.competitionId,
                  );
                }}
                className="flex row items-center gap-10 px-md py-lg rounded-borders overflow-hidden cursor-pointer yellowHoverBackground"
                style={{
                  height: 45,
                  border: selectedCompetitionsIds?.includes(
                    fixedCompetitionItem?.competitionId,
                  )
                    ? '1px solid transparent'
                    : '1px solid white',
                  backgroundColor: selectedCompetitionsIds?.includes(
                    fixedCompetitionItem?.competitionId,
                  )
                    ? 'var(--appYellow)'
                    : '#003069',
                }}>
                {!!isLoggedIn && (
                  <div
                    className="flex"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onFavouriteSelect(fixedCompetitionItem?.competitionId);
                    }}>
                    <StarIcon
                      fill={
                        Object.keys(
                          favouriteCompetitionsObject || {},
                        )?.includes(`${fixedCompetitionItem?.competitionId}`)
                          ? 'var(--appYellow)'
                          : 'transparent'
                      }
                    />
                  </div>
                )}
                <img
                  height={20}
                  className="competitionIcon"
                  src={fixedCompetitionItem?.logo}
                  alt={fixedCompetitionItem?.title}
                  style={{maxWidth: 30, objectFit: 'contain'}}
                />
                <span
                  className={`font-12 bold-700 ellipsis ${
                    selectedCompetitionsIds?.includes(
                      fixedCompetitionItem?.competitionId,
                    )
                      ? 'blueText'
                      : 'whiteText'
                  }`}>
                  {fixedCompetitionItem?.title}
                </span>
              </div>
            ))
          ) : (
            <span className="font-12 bold-700 blueText flex-shrink whiteText">
              {t('noDataToShow')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SideBarTopLeagues);
