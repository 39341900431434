import React, {memo} from 'react';

const SportTeamItem = ({
  team1Id = '',
  team2Id = '',
  team1Name = '',
  team2Name = '',
  sportAlias = '',
  regionAlias = '',
  onPressHandler = () => {},
}) => {
  return (
    <div className="sportItemContainer cursor-pointer">
      <div onClick={onPressHandler} className="container">
        <div className="wrapper">
          <div
            className={`${
              !!team2Name ? 'team2Container' : 'teamContainer'
            } overflow-hidden`}>
            <img
              width={!!team2Name ? 11 : 25}
              height={!!team2Name ? 11 : 25}
              alt={team1Id}
              src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                team1Id / 2000,
              )}/${team1Id}.png`}
            />
            <div className="overflow-hidden column">
              <span className="teamName ellipsis">{team1Name}</span>
              {!team2Name && (
                <span className="gameInfoContainer">
                  {`${sportAlias}, ${regionAlias}`}
                </span>
              )}
            </div>
          </div>
          {team2Name && <span className="teamName">-</span>}
          {team2Name && (
            <div
              className={`${
                !!team2Name ? 'team2Container' : 'teamContainer'
              } overflow-hidden`}>
              <img
                width={!!team2Name ? 11 : 25}
                height={!!team2Name ? 11 : 25}
                alt={team2Id}
                src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                  team2Id / 2000,
                )}/${team2Id}.png`}
              />
              <span className="teamName ellipsis">{team2Name}</span>
            </div>
          )}
        </div>
        {team2Name && (
          <span className="gameInfoContainer">
            {`${sportAlias}, ${regionAlias}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default SportTeamItem;
