import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import './SelectedCompetitions.scss';
import {useSelector} from 'react-redux';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {GET_SELECTED_COMPETITIONS} from '../../../../Constants/Socket';
import {POPULAR_LEAGUES_COMPETITIONS_DATA} from '../../../../Constants/PopularLeaguesCompetitionsData';

import SelectedCompetitionItem from './SelectedCompetitionItem';

import trashIcon from '../../../../Assets/Icons/Globals/trash.svg';

const SelectedCompetitions = () => {
  const {t} = useTranslation();
  const {getSelectedCompetitions} = useSocket();
  const [getParam, setGetParam] = useSearchParams();

  const wssSocket = useSelector(getWssSocket);

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);

  const selectedCompetitionsArray = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.map?.(item => +item);
  }, [regionParam]);

  const [selectedCompetitions, setSelectedCompetitions] = useState([]);

  const deleteAllHandler = useCallback(() => {
    getParam.delete('region');
    setGetParam(getParam);
  }, [getParam, setGetParam]);

  useEffect(() => {
    getSelectedCompetitions(selectedCompetitionsArray);
  }, [getSelectedCompetitions, selectedCompetitionsArray]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case GET_SELECTED_COMPETITIONS:
          if (data?.code === 0) {
            const updatedData = selectedCompetitionsArray?.map(id => {
              const foundItem =
                POPULAR_LEAGUES_COMPETITIONS_DATA?.[sportParam]?.find(
                  item => item?.competitionId === id,
                ) ||
                Object.values(data?.data?.data?.competition || {})?.find(
                  item => item?.id === id,
                );
              return {
                logo: foundItem?.logo || '',
                name: foundItem?.title || foundItem?.name || '',
                id: foundItem?.competitionId || foundItem?.id || '',
              };
            });
            setSelectedCompetitions(updatedData);
          }
          break;
        default:
          break;
      }
    },
    [selectedCompetitionsArray, sportParam],
  );

  useEffect(() => {
    wssSocket?.addEventListener?.('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener?.('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      style={{boxShadow: ' 0px 4px 4px 0px #00000040', marginBottom: 4}}
      className="selectedCompetitionsContainer blueBackground pl-xl py-lg">
      <div className="z-index-11 row items-center">
        <div className="row items-center gap-8 flex-shrink">
          <span className="whiteText text-uppercase font-15 bold-600">
            {t('selectedLeagues')} {`(${selectedCompetitionsArray?.length})`}
          </span>
          <div
            style={{
              width: 30,
              height: 30,
            }}
            onClick={deleteAllHandler}
            className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer">
            <img width={13} height={15} alt="delete" src={trashIcon} />
          </div>
        </div>
        <Swiper
          freeMode
          keyboard
          mousewheel
          navigation
          spaceBetween={15}
          slidesPerView="auto"
          slidesOffsetAfter={45}
          slidesOffsetBefore={45}
          key={selectedCompetitions?.length}
          modules={[Keyboard, FreeMode, Navigation]}
          className="selectedCompetitionsSwiper full-width overflow-hidden">
          {(selectedCompetitions || [])?.map(item => (
            <SwiperSlide key={item?.id}>
              <SelectedCompetitionItem item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default memo(SelectedCompetitions);
