import {memo, useMemo} from 'react';
import Skeleton from 'react-loading-skeleton';

const CarouselSkeletons = ({
  minHeight = 150,
  currentHeight = null,
  baseColor = 'var(--semiLightGray)',
  highlightColor = 'var(--darkenGray)',
}) => {
  const height = useMemo(
    () =>
      document.querySelector('.homeCarouselContainer')?.clientWidth / 3.92 - 50,
    [],
  );

  return (
    <Skeleton
      count={1}
      width="80%"
      duration={0.7}
      className="my-sm"
      borderRadius="5px"
      baseColor={baseColor}
      style={{minHeight: minHeight}}
      highlightColor={highlightColor}
      height={currentHeight || height}
      containerClassName="justify-center flex"
    />
  );
};

export default memo(CarouselSkeletons);
