import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LiveChatWidget} from '@livechat/widget-react';
import {useTranslation} from 'react-i18next';
import './Layout.scss';

import {
  setSubIds,
  setSports,
  setBoostedGameIds,
  setIsSportDataLoading,
  setActiveFilterParams,
  setIsGameDataLoading,
  setFavouriteCompetitionsObject,
} from '../Redux/SportSlice';
import store from '../Redux/store';
import {
  getSid,
  getWssSocket,
  getRecaptcha,
  getAuthModal,
  getIsLoggedIn,
  getShowBetSlip,
  getShowSearchModal,
  getShowMobileBetSlip,
  getIsContentScrolled,
  getMainMenuModal,
} from '../Redux/AppSlice/AppSelectors';
import {setProviders} from '../Redux/CasinoSlice';
import {
  setIsContentScrolled,
  setShowBetSlip,
  setShowMobileBetSlip,
} from '../Redux/AppSlice';
import {
  getSubIds,
  getSportsArray,
  getBoostedGameIds,
  getFavouriteCompetitionsObject,
} from '../Redux/SportSlice/SportSelectors';
import {
  getShowCasinoSearchModal,
  getShowCasinoSideBar,
} from '../Redux/CasinoSlice/CasinoSelectors';

import {getNextSevenDaysUnixTimestamps} from '../Utils/GetNextSevenDaysFilters';

import useSocket from '../Hooks/UseSocket';
import useCasino from '../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../Hooks';

import {
  LICENSE_ID,
  GET_ALL_SPORTS,
  GET_BOOSTED_SPORTS,
  GET_BOOSTED_SELECTIONS,
  GET_USER_DATA_PERSONALIZATION,
} from '../Constants/Socket';
import {
  MATCH_FILTERS,
  MARKETS_OUTRIGHT,
  FILTERS_TODAY_NAME,
  FILTERS_LIVE_CALENDAR,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../Constants/MatchFilters';
import {BREAKPOINT_XS, SPORTS_PAGE_SIDEBAR_WIDTH} from '../Constants/Globals';
import {
  CATEGORY_BOOSTED_RTP_GAMES_ID,
  CATEGORY_SKILL_GAMES_ID,
} from '../Constants/CasinoMenuFilter';
import {FixedCasinoProviders} from '../Constants/FixedCasinoProviders';

import {
  AppIntro,
  AppHeader,
  AppBetSlip,
  AppCarousel,
  AppJackpot,
  AppRecaptcha,
  AppSuccessModal,
  AppCallToAction,
  AppNewCallToAction,
} from '../Components/UI';
import Footer from '../Components/Footer';
import MyBets from '../Components/MyBets';
import AuthStack from '../Components/Auth';
import Messages from '../Components/Messages';
import MainMenu from '../Components/MainMenu';
import NewFooter from '../Components/NewFooter';
import Promotions from '../Components/Promotions';
import NewMainMenu from '../Components/NewMainMenu';
import HomeSports from '../Components/Home/HomeSports';
import TopWinners from '../Components/Footer/TopWinners';
import SideBarRegions from '../Components/Home/Sports/SportsList/SideBarRegions';
import SportFilters from '../Components/Home/SportFilters';
import MobileHeader from '../Components/Header/MobileHeader';
import MobileBottomTab from '../Components/Home/MobileBottomTab';
import SearchModal from '../Components/Home/ActionBar/SearchModal';
import CommunityChatWidget from '../Components/CommunityChatWidget';
import BetSlipSuccessModal from '../Components/BetSlip/Components/SuccessModal';
import CasinoSearchModal from '../Components/Casino/CasinoActionBar/CasinoSearchModal';

import coloredClose from '../Assets/Icons/Globals/coloredClose.svg';
import {ReactComponent as HideBetslipArrow} from '../Assets/Icons/Casino/hideBetslipArrow.svg';

import {ReactComponent as BetSlipIcon} from '../Assets/Icons/Globals/betslip.svg';
import {ReactComponent as CloseIcon} from '../Assets/Icons/Globals/closeTransparent.svg';
import SideBarProviders from '../Components/Casino/Providers/SideBarProviders';
import HomeProviders from '../Components/Home/HomeProviders';
import SelectedCompetitions from '../Components/Home/Sports/SportsList/SelectedCompetitions';
import NewSearchModal from '../Components/Home/ActionBar/newSearchModal';

const Layout = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const startYRef = useRef(0);
  const translateYRef = useRef(0);
  const isDraggingRef = useRef(false);
  const mobileBetslipRef = useRef(null);

  // useOutSideClickHandler(mobileBetslipRef, () =>
  //   dispatch(setShowMobileBetSlip(false)),
  // );

  const {getCasinoProviders} = useCasino();
  const {
    getGames,
    getSports,
    getBoostedGames,
    getInitialGames,
    getBoostedSports,
    getBoostedSelections,
    getFavouriteCompetition,
  } = useSocket();

  const sid = useSelector(getSid);
  const subIds = useSelector(getSubIds);
  const wssSocket = useSelector(getWssSocket);
  const recaptcha = useSelector(getRecaptcha);
  const authModal = useSelector(getAuthModal);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const sportsData = useSelector(getSportsArray);
  const showBetSlip = useSelector(getShowBetSlip);
  const boostedIds = useSelector(getBoostedGameIds);
  const isScrolled = useSelector(getIsContentScrolled);
  const mainMenuModal = useSelector(getMainMenuModal);
  const showSearchModal = useSelector(getShowSearchModal);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);
  const showCasinoSideBar = useSelector(getShowCasinoSideBar);
  const showCasinoSearchModal = useSelector(getShowCasinoSearchModal);

  const contentWrapperRef = useRef(null);
  const previousGetParam = useRef('');

  const betBuilderIdParam = useMemo(
    () => getParam.get('betBuilderId'),
    [getParam],
  );
  const dayParam = useMemo(() => getParam.get('day'), [getParam]);
  const casinoGameIdParam = useMemo(
    () => getParam.get('casinoGameId'),
    [getParam],
  );
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );
  // const getMarketParam = useMemo(() => getParam.get('market'), [getParam]);
  const shareBetIdParam = useMemo(() => getParam.get('shareBetId'), [getParam]);
  // const casinoCategoryParam = useMemo(
  //   () => getParam.get('category'),
  //   [getParam],
  // );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  // const requestGames = useCallback(
  //   showLoader => {
  //     if (params?.region && params?.competition) {
  //       getGames({
  //         showLoader,
  //         regionAlias: params?.region,
  //         activeSportAlias: params?.sport,
  //         gameFilters: MATCH_FILTERS?.[params?.filter]?.status,
  //         competitionId: params?.competition?.split('_')?.map(item => +item),
  //       });
  //     } else {
  //       const searchQuery = store?.getState?.()?.sport?.searchQuery;
  //       getInitialGames({
  //         showLoader,
  //         search: searchQuery,
  //         activeSportAlias: params?.sport,
  //         gameFilters:
  //           params?.filter === FILTERS_LIVE_CALENDAR
  //             ? {
  //                 game: {
  //                   is_live: false,
  //                   start_ts: {
  //                     '@gte':
  //                       +getDayParam?.split('_')?.[0] ||
  //                       getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
  //                     '@lt':
  //                       +getDayParam?.split('_')?.[1] ||
  //                       getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
  //                   },
  //                 },
  //               }
  //             : MATCH_FILTERS?.[params?.filter]?.status,
  //         withoutLimit:
  //           params?.filter === FILTERS_TODAY_NAME ||
  //           params?.filter === FILTERS_LIVE_CALENDAR,
  //       });
  //     }
  //   },
  //   [
  //     getGames,
  //     getDayParam,
  //     params?.sport,
  //     params?.filter,
  //     params?.region,
  //     getInitialGames,
  //     params?.competition,
  //   ],
  // );

  const handleTouchStart = useCallback(
    e => {
      if (width > BREAKPOINT_XS) return;
      const headerDragArea = document.getElementById('headerDragArea');
      if (
        headerDragArea &&
        (headerDragArea?.contains(e?.target) || e.target?.id === 'minimizer') &&
        !casinoGameIdParam &&
        location?.pathname !== '/casino/poker'
      ) {
        if (
          e?.target?.id !== 'betslipCloseContainer' &&
          e?.target?.id !== 'betslipCloseIcon'
        ) {
          mobileBetslipRef.current.style.transition = 'none';
        }
        startYRef.current = e?.touches?.[0]?.clientY;
        isDraggingRef.current = true;
      }
    },
    [casinoGameIdParam, location?.pathname, width],
  );

  const handleTouchMove = useCallback(
    e => {
      const headerDragArea = document.getElementById('headerDragArea');
      if (
        !headerDragArea ||
        !(
          headerDragArea?.contains(e?.target) || e.target?.id === 'minimizer'
        ) ||
        !isDraggingRef.current ||
        !!casinoGameIdParam ||
        location?.pathname === '/casino/poker' ||
        width > BREAKPOINT_XS
      )
        return;
      const distance = e?.touches?.[0]?.clientY - startYRef.current;
      if (distance > 0) {
        translateYRef.current = distance;
        mobileBetslipRef.current.style.transform = `translateY(${translateYRef.current}px)`;
      }
    },
    [casinoGameIdParam, location?.pathname, width],
  );

  const handleTouchEnd = useCallback(() => {
    if (width > BREAKPOINT_XS) return;
    if (
      translateYRef.current > 0 &&
      !casinoGameIdParam &&
      location?.pathname !== '/casino/poker'
    ) {
      mobileBetslipRef.current.style.transition = '';
      const threshold = 300;
      if (translateYRef.current > threshold) {
        dispatch(setShowMobileBetSlip(false));
      }
      mobileBetslipRef.current.style.transform = '';
      isDraggingRef.current = false;
      translateYRef.current = 0;
    }
  }, [casinoGameIdParam, dispatch, location?.pathname, width]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      if (data?.rid === GET_USER_DATA_PERSONALIZATION) {
        dispatch(
          setFavouriteCompetitionsObject(
            data?.data?.details?.data?.sportFavoriteCompetition?.configs,
          ),
        );

        if (gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME) {
          const favouriteCompetitionsArray = Object?.keys(
            data?.data?.details?.data?.sportFavoriteCompetition?.configs || {},
          )?.map(item => +item);

          getInitialGames({
            withoutLimit: true,
            activeSportAlias: sportParam,
            gameFilters: MATCH_FILTERS?.[gameFilterParam]?.status,
            competitionsIdsArray: favouriteCompetitionsArray,
          });
        }
      }
      // const data = JSON.parse(event?.data);
      // const gameCount = data?.data?.[subIds?.sports]?.sport;
      //
      // if (data?.rid === GET_ALL_SPORTS || data?.rid === GET_BOOSTED_SPORTS) {
      //   dispatch(setSubIds({sports: data?.data?.subid}));
      //
      //   const sportsArray = Object.values(data?.data?.data?.sport || {})?.sort(
      //     (a, b) => a?.order - b?.order,
      //   );
      //
      //   const initialSport = sportsArray?.[0];
      //   if (params?.region && params?.competition) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         params?.sport
      //       }/${params?.region}/${params?.competition}`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   } else if (params?.sport) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         params?.sport
      //       }`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : !!shareBetId
      //         ? {
      //             search: createSearchParams({
      //               shareBetId: shareBetId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   } else if (!!params?.filter) {
      //     navigate({
      //       pathname: `/sports/${MATCH_FILTERS?.[params?.filter]?.name}/${
      //         initialSport?.alias
      //       }`,
      //       ...(!!selectedGameId
      //         ? {
      //             search: createSearchParams({
      //               game: selectedGameId,
      //             }).toString(),
      //           }
      //         : {}),
      //     });
      //   }
      //
      //   dispatch(setSports(sportsArray));
      //   setTimeout(() => {
      //     dispatch(setIsSportDataLoading(false));
      //   }, 100);
      // } else if (data?.rid === GET_BOOSTED_SELECTIONS) {
      //   const gameIds = Object.keys(data?.data?.details || {})?.map(
      //     item => +item,
      //   );
      //
      //   dispatch(setBoostedGameIds(gameIds));
      //
      //   getBoostedSports({
      //     gameIdsArray: gameIds,
      //   });
      //
      //   getBoostedGames({
      //     gameIdsArray: gameIds,
      //     activeSportAlias: params?.sport,
      //   });
      // } else if (Object.keys(gameCount || {})?.length > 0) {
      //   const newSports = [...sportsData];
      //
      //   Object.entries(data?.data?.[subIds?.sports]?.sport || {})?.map(el => {
      //     const elementElementIndex = sportsData?.findIndex(
      //       item => +item?.id === +el?.[0],
      //     );
      //     newSports[elementElementIndex] = {
      //       ...newSports[elementElementIndex],
      //       ...el?.[1],
      //     };
      //     if (
      //       el?.[1]?.game > sportsData?.[elementElementIndex]?.game &&
      //       newSports[elementElementIndex]?.alias === params?.sport
      //     ) {
      //       requestGames(false);
      //     }
      //   });
      //
      //   dispatch(setSports(newSports));
      // }
    },
    [gameFilterParam, dispatch, getInitialGames, sportParam],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  // useEffect(() => {
  //   if (!showSearchModal) {
  //     getParam.delete('search');
  //     setGetParam(getParam);
  //   }
  // }, [showSearchModal]);

  // useEffect(() => {
  //   if (
  //     params?.filter === FILTERS_LIVE_CALENDAR &&
  //     previousGetParam.current !== getDayParam
  //   ) {
  //     getParam.set(
  //       'day',
  //       `${
  //         getDayParam?.split('_')?.[0] ||
  //         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay
  //       }_${
  //         getDayParam?.split('_')?.[1] ||
  //         getNextSevenDaysUnixTimestamps()?.[0]?.endOfDay
  //       }`,
  //     );
  //     setGetParam(getParam);
  //     previousGetParam.current = `${
  //       getDayParam?.split('_')?.[0] ||
  //       getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay
  //     }_${
  //       getDayParam?.split('_')?.[1] ||
  //       getNextSevenDaysUnixTimestamps()?.[0]?.endOfDay
  //     }`;
  //     requestGames(true);
  //   }
  // }, [getDayParam, params?.filter]);

  // useEffect(() => {
  //   if (width < BREAKPOINT_XS && !getMarketParam) {
  //     getParam.set('market', 'matchResult');
  //     setGetParam(getParam);
  //   }
  // }, [width, getMarketParam]);

  // useEffect(() => {
  //   if (!!sid) {
  //     const activeFilterParams = store.getState()?.sport?.activeFilterParams;
  //
  //     if (params?.category === 'liveCasino' || params?.tab === 'liveCasino') {
  //       dispatch(setProviders(FixedCasinoProviders));
  //     } else if (
  //       params?.category === 'slots' ||
  //       params?.category === 'skill' ||
  //       params?.category === 'boostedRTP' ||
  //       typeof params?.tab !== 'undefined'
  //     ) {
  //       dispatch(setIsSportDataLoading(true));
  //       getCasinoProviders({
  //         categoryId:
  //           params?.category === 'skill'
  //             ? CATEGORY_SKILL_GAMES_ID
  //             : params?.category === 'boostedRTP'
  //             ? CATEGORY_BOOSTED_RTP_GAMES_ID
  //             : null,
  //       })
  //         ?.then(res =>
  //           dispatch(
  //             setProviders(
  //               res?.data?.providers?.filter(item => item?.name !== 'EVB'),
  //             ),
  //           ),
  //         )
  //         ?.finally(() =>
  //           setTimeout(() => dispatch(setIsSportDataLoading(false)), 100),
  //         );
  //     } else if (params?.filter || params?.filter !== activeFilterParams) {
  //       dispatch(setActiveFilterParams(MATCH_FILTERS?.[params?.filter]?.name));
  //       if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
  //         getBoostedSelections();
  //       } else {
  //         getSports({
  //           gameFilters:
  //             params?.filter === FILTERS_LIVE_CALENDAR
  //               ? {
  //                   game: {
  //                     start_ts: {
  //                       '@gte':
  //                         +getDayParam?.split('_')?.[0] ||
  //                         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
  //                       '@lt':
  //                         +getDayParam?.split('_')?.[1] ||
  //                         getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
  //                     },
  //                   },
  //                 }
  //               : MATCH_FILTERS?.[params?.filter]?.status,
  //         });
  //       }
  //     }
  //     if (params?.sport) {
  //       if (params?.filter === FILTERS_BOOSTED_ODDS_NAME) {
  //         const boostedGameIds = store.getState()?.sport?.boostedGameIds;
  //         if (boostedGameIds?.length > 0) {
  //           getBoostedGames({
  //             gameIdsArray: boostedGameIds,
  //             activeSportAlias: params?.sport,
  //           });
  //         }
  //       } else {
  //         if (
  //           params?.filter !== FILTERS_BOOSTED_ODDS_NAME &&
  //           params?.filter !== FILTERS_LIVE_CALENDAR
  //         ) {
  //           requestGames();
  //         }
  //       }
  //     }
  //   }
  // }, [
  //   sid,
  //   params?.tab,
  //   params?.sport,
  //   params?.filter,
  //   params?.region,
  //   params?.category,
  //   params?.competition,
  // ]);

  // useEffect(() => {
  //   if (
  //     location.pathname === '/' ||
  //     location.pathname === '/sports' ||
  //     location.pathname === '/sports/'
  //   ) {
  //     navigate(`/sports/${FILTERS_TODAY_NAME}`);
  //   }
  // }, []);

  useEffect(() => {
    if (
      !!gameFilterParam &&
      !!sportParam &&
      !!sid &&
      !dayParam &&
      !regionParam &&
      gameFilterParam !== FILTERS_FAVOURITE_COMPETITIONS_NAME &&
      gameFilterParam !== FILTERS_BOOSTED_ODDS_NAME
    ) {
      getInitialGames({
        withoutLimit: true,
        activeSportAlias: sportParam,
        gameFilters: MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    }
  }, [sid, dayParam, sportParam, regionParam, gameFilterParam]);

  useEffect(() => {
    if (
      !!gameFilterParam &&
      !!sportParam &&
      !!sid &&
      !dayParam &&
      !regionParam &&
      gameFilterParam !== FILTERS_FAVOURITE_COMPETITIONS_NAME &&
      gameFilterParam === FILTERS_BOOSTED_ODDS_NAME
    ) {
      if (boostedIds !== null) {
        getBoostedGames({
          activeSportAlias: sportParam,
          gameIdsArray: Object.keys(boostedIds)?.map(item => +item),
        });
      }
    }
  }, [sid, dayParam, sportParam, regionParam, gameFilterParam, boostedIds]);

  useEffect(() => {
    if (!!regionParam && !!sid) {
      const splitArray = regionParam?.split?.('-');
      const competitions =
        splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
      const flattenedArray = (competitions || [])
        ?.flat?.(Infinity)
        ?.map?.(item => +item);
      getGames({
        activeSportAlias: sportParam,
        competitionId: flattenedArray,
        gameFilters: !!dayParam
          ? {
              game: {
                is_live: false,
                start_ts: {
                  '@gte':
                    +dayParam?.split('_')?.[0] ||
                    getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
                  '@lt':
                    +dayParam?.split('_')?.[1] ||
                    getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
                },
              },
            }
          : gameFilterParam === FILTERS_BOOSTED_ODDS_NAME && boostedIds !== null
          ? {
              game: {
                id: {
                  '@in': Object.keys(boostedIds || {})?.map(item => +item),
                },
              },
            }
          : MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    }
  }, [gameFilterParam, dayParam, regionParam, sid, boostedIds]);

  useEffect(() => {
    if (!!dayParam && !!sid && !regionParam) {
      const arr = dayParam?.split?.('-')?.map(item => ({
        start_ts: {
          '@gte':
            +item?.split('_')?.[0] ||
            getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
          '@lt':
            +item?.split('_')?.[1] ||
            getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
        },
      }));

      getInitialGames({
        activeSportAlias: sportParam,
        withoutLimit: true,
        gameFilters: {
          game: {
            '@or': arr,
            is_live: false,
            show_type: {'@ne': MARKETS_OUTRIGHT},
            // '@or': [
            //   {
            //     start_ts: {
            //       '@gte':
            //         +`1738612800_1738699199`?.split('_')?.[0] ||
            //         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
            //       '@lt':
            //         +`1738612800_1738699199`?.split('_')?.[1] ||
            //         getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
            //     },
            //   },
            //   {
            //     start_ts: {
            //       '@gte':
            //         +`1738267200_1738353599`?.split('_')?.[0] ||
            //         getNextSevenDaysUnixTimestamps()?.[0]?.startOfDay,
            //       '@lt':
            //         +`1738267200_1738353599`?.split('_')?.[1] ||
            //         getNextSevenDaysUnixTimestamps()?.[1]?.startOfDay,
            //     },
            //   },
            // ],
          },
        },
      });
    }
  }, [regionParam, dayParam, sid]);

  useEffect(() => {
    if (!!sid && location.pathname?.includes('/sports')) {
      getSports({rid: GET_ALL_SPORTS});
      getBoostedSelections();
    }
  }, [sid]);

  useEffect(() => {
    if (!!sid && !!isLoggedIn && location.pathname?.includes('/sports')) {
      if (gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME) {
        dispatch(setIsGameDataLoading(true));
      }

      getFavouriteCompetition();
    }
  }, [sid, isLoggedIn, gameFilterParam, sportParam, sportGameIdParam]);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/sports`);
    }
  }, []);

  useEffect(() => {
    if (
      showMobileBetSlip ||
      (!!casinoGameIdParam && location?.pathname?.includes('casino'))
    ) {
      document.querySelector('body').classList.add('overflow-hidden');
      document.querySelector('html').classList.add('overflow-hidden');
    } else {
      document.querySelector('html').classList.remove('overflow-hidden');
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [location?.pathname, casinoGameIdParam, showMobileBetSlip]);

  return (
    <div
      className={`mx-auto appLayout ${
        width < BREAKPOINT_XS
          ? isLoggedIn
            ? 'loggedInPadding'
            : 'notLoggedInPadding'
          : ''
      } `}>
      <>
        {width < BREAKPOINT_XS ? (
          !sportGameIdParam && (
            <MobileHeader
              showFilters={
                !casinoGameIdParam ||
                (!!casinoGameIdParam && authModal?.isVisible)
              }
            />
          )
        ) : (
          <AppHeader />
        )}
      </>
      <div
        className="contentContainerWrapper row full-width "
        style={
          (!location?.pathname?.includes('/casino/lobby') &&
            width < BREAKPOINT_XS &&
            !!sportGameIdParam &&
            !betBuilderIdParam) ||
          (!location?.pathname?.includes('/casino/lobby') &&
            width > BREAKPOINT_XS)
            ? {minHeight: '100dvh'}
            : {}
        }>
        {!location?.pathname?.includes('/casino/poker') &&
        location?.pathname?.includes('casino') ? (
          <SideBarProviders />
        ) : (
          <SideBarRegions />
        )}
        <div
          className="column overflow-hidden"
          style={{
            width:
              location?.pathname?.includes('jackpot') ||
              !showCasinoSideBar ||
              (width < 1360 && width > BREAKPOINT_XS && !!sportGameIdParam) ||
              width < BREAKPOINT_XS
                ? '100%'
                : '100%',
            // `calc(100% - ${SPORTS_PAGE_SIDEBAR_WIDTH}px)`,
          }}>
          {location.pathname !== '/jackpot' && <AppCarousel />}
          {((width < BREAKPOINT_XS && location?.pathname?.includes('lobby')) ||
            (!(width < BREAKPOINT_XS) &&
              location?.pathname?.includes('slots'))) && <AppJackpot />}
          {!location?.pathname?.includes('casino') &&
            location.pathname !== '/jackpot' && (
              <div
                className={`darkBlueBackground ${
                  width < BREAKPOINT_XS && !!sportGameIdParam && 'hidden'
                }`}>
                <HomeSports />
                {/*<SportFilters isMobile />*/}
              </div>
            )}
          {!location?.pathname?.includes('casino') &&
            location.pathname !== '/jackpot' &&
            !(width < BREAKPOINT_XS && !!sportGameIdParam) && (
              <SportFilters isMobile />
            )}
          {!!regionParam &&
            !location?.pathname?.includes('casino') &&
            location.pathname !== '/jackpot' &&
            width > BREAKPOINT_XS && <SelectedCompetitions />}
          {location?.pathname?.includes('/casino/') &&
            !location?.pathname?.includes('lobby') && <HomeProviders />}

          <div
            ref={contentWrapperRef}
            onScroll={e => {
              if (width < BREAKPOINT_XS) {
                dispatch(setIsContentScrolled(e?.target?.scrollTop > 50));
              }
            }}
            className={`contentWrapper row gap-5 ${
              width < BREAKPOINT_XS
                ? ''
                : location?.pathname?.includes('casino')
                ? 'full-height'
                : ''
            } ${location?.pathname?.includes('casino') ? 'px-md' : ''} ${
              width > BREAKPOINT_XS ? 'rounded-top-borders' : ''
            } ${
              location?.pathname?.includes('/jackpot') ? 'overflow-hidden' : ''
            }`}>
            <div
              className="content"
              style={
                showBetSlip && width > BREAKPOINT_XS
                  ? {width: '100%' /*`calc(100% - 315px)`*/}
                  : {width: '100%'}
              }>
              {location.pathname !== '/jackpot' &&
                location?.pathname !== '/casino/poker' && (
                  <>
                    {/*{location?.pathname?.includes('casino') && <AppCarousel />}*/}
                    {/*{width > BREAKPOINT_XS && <AppJackpot />}*/}
                  </>
                )}
              <Outlet />

              {/*<AppCallToAction />*/}
              {/*<AppNewCallToAction />*/}
            </div>
          </div>
        </div>
        {/*{(width > BREAKPOINT_XS || showMobileBetSlip) && (*/}
        {/*  <div*/}
        {/*    style={*/}
        {/*      width < BREAKPOINT_XS*/}
        {/*        ? {position: 'absolute', top: 0, width: '100%', zIndex: 16}*/}
        {/*        : {}*/}
        {/*    }*/}
        {/*    className={`appBetSlipWrapper_desktop blueBackground rounded-top--borders column ${*/}
        {/*      showMobileBetSlip ? 'slide-up' : 'slide-down'*/}
        {/*    } appBetSlipWrapper`}>*/}
        {/*    <div className="row items-center  justify-between yellowBackground pa-sm">*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          width: 40,*/}
        {/*          height: 40,*/}
        {/*        }}*/}
        {/*      />*/}
        {/*      <span className="font-20 blueText bold-600">{t('myBets')}</span>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          width: 40,*/}
        {/*          height: 40,*/}
        {/*        }}*/}
        {/*        className="whiteBackground items-center justify-center flex rounded-borders">*/}
        {/*        <img*/}
        {/*          alt="close"*/}
        {/*          width={10}*/}
        {/*          height={10}*/}
        {/*          src={coloredClose}*/}
        {/*          className="cursor-pointer"*/}
        {/*          onClick={() => dispatch(setShowBetSlip(false))}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div*/}
        {/*      className={`full-height betSlipDesktopWrapper rounded-borders`}>*/}
        {/*      <AppBetSlip />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        {width > BREAKPOINT_XS && (
          <div
            style={{
              ...(width < BREAKPOINT_XS ? {display: 'none'} : {}),
            }}
            className={`${
              width < BREAKPOINT_XS
                ? 'appBetSlipWrapper_mobile flex items-center justify-center full-width'
                : 'appBetSlipWrapper_desktop blueBackground rounded-top--borders'
            } ${
              width >= BREAKPOINT_XS ? (!!showBetSlip ? 'column' : 'hide') : ''
            } ${
              showMobileBetSlip && width < BREAKPOINT_XS
                ? 'slide-up'
                : 'slide-down'
            } appBetSlipWrapper`}>
            {width > BREAKPOINT_XS && (
              <div
                className="row items-center  justify-between yellowBackground pa-sm"
                style={{
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}>
                <div
                  style={{
                    width: 26,
                    height: 26,
                  }}
                />
                <span className="font-15 blueText bold-600">{t('myBets')}</span>
                <div
                  style={{
                    width: 26,
                    height: 26,
                  }}
                  onClick={() => dispatch(setShowBetSlip(false))}
                  className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer z-index-1">
                  <img alt="close" width={10} height={10} src={coloredClose} />
                </div>
              </div>
            )}
            <div
              className={`${
                width > BREAKPOINT_XS
                  ? 'full-height betSlipDesktopWrapper'
                  : 'full-width flex justify-center'
              } rounded-borders`}>
              <AppBetSlip />
            </div>
          </div>
        )}
      </div>
      {!!getParam?.get('showPromotions') && <Promotions />}
      <AuthStack />
      {width < BREAKPOINT_XS && !mainMenuModal?.isDesktop ? (
        <NewMainMenu />
      ) : (
        <MainMenu />
      )}
      {width < BREAKPOINT_XS && <MobileBottomTab />}
      {/*<Messages />*/}
      <MyBets />
      <AppSuccessModal />
      {/*{showSearchModal && <SearchModal />}*/}
      <NewSearchModal />
      {showCasinoSearchModal && <CasinoSearchModal />}
      {recaptcha?.isVisible && <AppRecaptcha />}
      <AppIntro />
      {width > BREAKPOINT_XS && (
        <LiveChatWidget
          license={LICENSE_ID?.toString?.()}
          visibility={
            width > BREAKPOINT_XS && location?.pathname !== '/casino/poker'
              ? 'minimized'
              : 'hidden'
          }
        />
      )}
      {!!shareBetIdParam && (
        <BetSlipSuccessModal
          isSharedBet
          betId={shareBetIdParam}
          isOpen={!!shareBetIdParam}
          setIsOpen={() => {
            getParam.delete('shareBetId');
            setGetParam(getParam);
          }}
        />
      )}
      {/*<CommunityChatWidget*/}
      {/*  show={width > BREAKPOINT_XS && isLoggedIn !== null}*/}
      {/*/>*/}
      {location?.pathname?.includes('casino/lobby') && (
        <div className="blueBackground">
          <div
            style={{
              width: '100%',
              boxShadow: '0px 4px 4px 0px #00000040',
              height: width < BREAKPOINT_XS ? 4 : 80,
            }}
            className="blueBackground"></div>
          <TopWinners />
        </div>
      )}

      {location.pathname !== '/jackpot' &&
        (location?.pathname?.includes('/casino')
          ? location?.pathname?.includes('/casino/lobby')
          : !sportGameIdParam || width > BREAKPOINT_XS) && <NewFooter />}
      {width < BREAKPOINT_XS && (
        <>
          <div
            className={`appBetSlipWrapper_mobile flex justify-center fit rounded-top--borders column appBetSlipWrapper ${
              location?.pathname === '/casino/poker' || !!casinoGameIdParam
                ? 'casino'
                : 'sports'
            } ${
              showMobileBetSlip
                ? `${
                    location?.pathname === '/casino/poker' ||
                    !!casinoGameIdParam
                      ? `slide-left`
                      : 'slide-up'
                  }`
                : `${
                    location?.pathname === '/casino/poker' ||
                    !!casinoGameIdParam
                      ? `slide-right`
                      : 'slide-down'
                  }`
            }`}
            ref={mobileBetslipRef}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            onTouchStart={handleTouchStart}
            style={{
              background: 'rgba(0, 0, 0, 0.7)',
              zIndex: showMobileBetSlip
                ? !!casinoGameIdParam || location?.pathname === '/casino/poker'
                  ? 100
                  : 21
                : 105,
            }}>
            <div
              className="row justify-center fit"
              style={{
                marginTop: 16,
                borderRadius: 12,
              }}>
              <div
                style={{
                  top: 18,
                  height: 3,
                  width: 50,
                  zIndex: 16,
                  borderRadius: 3,
                  position: 'absolute',
                }}
                id="minimizer"
                className="blueBackground"
              />
              <AppBetSlip />
            </div>
          </div>
          {(!!casinoGameIdParam || location?.pathname === '/casino/poker') &&
            showMobileBetSlip && (
              <div
                className="cursor-pointer betslipToggleButton_close"
                onClick={() => dispatch(setShowMobileBetSlip(false))}>
                <HideBetslipArrow style={{transform: 'scale(1.5)'}} />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default Layout;
