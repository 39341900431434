import React, {memo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';

import {AppSwitcher} from '../../../../UI';

import {ReactComponent as DownTriangle} from '../../../../../Assets/Icons/Globals/downTriangle.svg';

const Preferences = ({
  subscribedToSms,
  subscribedToEmail,
  setSubscribedToSms,
  setSubscribedToEmail,
  subscribedToPhoneCall,
  setSubscribedToPhoneCall,
  subscribedToPushNotification,
  subscribedToInternalMessages,
  setSubscribedToInternalMessages,
  setSubscribedToPushNotification,
}) => {
  const {t} = useTranslation();

  const [isExpanded, setExpanded] = useState(true);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  return (
    <div className="flex column gap-5 py-lg col-xs-12 col-sm-12 col-md-6">
      <div
        className="flex row items-center justify-between  pt-sm"
        {...getToggleProps({
          onClick: () => setExpanded(prevExpanded => !prevExpanded),
        })}>
        <span className="font-15 whiteText bold-700">{t('preferences')}</span>
      </div>
      <div {...getCollapseProps()}>
        <span className="whiteText bold-500 font-12">{t('notifyMeAbout')}</span>
        <div className="flex column gap-10 mt-md">
          <AppSwitcher
            type={3}
            labelClassName="whiteText bold-500 font-12"
            label={t('internalMessages')}
            isToggled={subscribedToInternalMessages}
            onToggle={() => setSubscribedToInternalMessages(prev => !prev)}
          />
          <AppSwitcher
            type={3}
            labelClassName="whiteText bold-500 font-12"
            label={t('pushNotifications')}
            isToggled={subscribedToPushNotification}
            onToggle={() => setSubscribedToPushNotification(prev => !prev)}
          />
          <AppSwitcher
            type={3}
            labelClassName="whiteText bold-500 font-12"
            label={t('phoneCall')}
            isToggled={subscribedToPhoneCall}
            onToggle={() => setSubscribedToPhoneCall(prev => !prev)}
          />
          <AppSwitcher
            type={3}
            labelClassName="whiteText bold-500 font-12"
            label={t('email')}
            isToggled={subscribedToEmail}
            onToggle={() => setSubscribedToEmail(prev => !prev)}
          />
          <AppSwitcher
            type={3}
            labelClassName="whiteText bold-500 font-12"
            label={t('sms')}
            isToggled={subscribedToSms}
            onToggle={() => setSubscribedToSms(prev => !prev)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Preferences);
