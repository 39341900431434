import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {getCurrency} from '../Redux/PartnerSlice/PartnerSelectors';

import {
  SITE_ID,
  GET_JACKPOTS,
  WSS_JACKPOT_ENDPOINT,
  SUBSCRIBE_TO_JACKPOT_DATA,
  GET_EXTERNAL_JACKPOT_LIST,
  UNSUBSCRIBE_FROM_JACKPOT_DATA,
} from '../Constants/Socket';

let reconnectionTimeout = null;

const useJackpotSocket = ({SourceId}) => {
  const currency = useSelector(getCurrency) || 'TZS';

  const jackpotId = useRef(null);

  const [poolList, setPoolList] = useState([]);
  const [isJackpotLoading, setIsJackpotLoading] = useState(false);
  const [jackpotAmount, setJackpotAmount] = useState(0);

  const [jackPotSocket, setJackPotSocket] = useState(
    new WebSocket(WSS_JACKPOT_ENDPOINT),
  );

  const wssJackpotSocket = useMemo(
    () => new WebSocket(WSS_JACKPOT_ENDPOINT),
    [],
  );

  const jackpotSocketQuery = useCallback(
    ({Command = '', Data = {}, RequestId = ''}) => {
      if (jackPotSocket?.readyState === WebSocket.OPEN) {
        jackPotSocket?.send?.(JSON.stringify?.({Command, Data, RequestId}));
      }
    },
    [jackPotSocket],
  );

  const onCloseCb = useCallback(() => {
    clearTimeout(reconnectionTimeout);
    reconnectionTimeout = setTimeout(() => {
      setJackPotSocket(new WebSocket(WSS_JACKPOT_ENDPOINT));
    }, 2000);
  }, [setJackPotSocket]);

  const getJackpots = useCallback(() => {
    jackpotSocketQuery({
      Command: GET_JACKPOTS,
      RequestId: GET_JACKPOTS,
      Data: {
        PartnerId: SITE_ID,
        // SourceId: SourceId ?? 5,
        Currency: currency || 'TZS',
      },
    });
  }, [currency, jackpotSocketQuery]);

  const getExternalJackpotList = useCallback(() => {
    jackpotSocketQuery({
      Command: GET_EXTERNAL_JACKPOT_LIST,
      RequestId: GET_EXTERNAL_JACKPOT_LIST,
      Data: {
        PartnerId: SITE_ID,
        // SourceId: SourceId ?? 5,
        Currency: currency || 'TZS',
      },
    });
  }, [currency, jackpotSocketQuery]);

  const subscribeToJackpotData = useCallback(
    ({jackpotId, intensity = 0}) => {
      jackpotSocketQuery({
        Command: SUBSCRIBE_TO_JACKPOT_DATA,
        RequestId: SUBSCRIBE_TO_JACKPOT_DATA,
        Data: {
          PartnerId: SITE_ID,
          Intensity: intensity,
          JackPotId: jackpotId,
          Currency: currency || 'TZS',
        },
      });
    },
    [currency, jackpotSocketQuery],
  );

  const unsubscribeFromJackpotData = useCallback(
    () =>
      jackpotSocketQuery({
        RequestId: SUBSCRIBE_TO_JACKPOT_DATA,
        Command: UNSUBSCRIBE_FROM_JACKPOT_DATA,
        Data: {
          JackPotId: jackpotId.current,
        },
      }),
    [jackpotSocketQuery],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);

      switch (data?.RequestId) {
        case GET_JACKPOTS:
          if (data?.Status === 1) {
            getExternalJackpotList();
          }
          break;
        case GET_EXTERNAL_JACKPOT_LIST:
          if (data?.Status === 1 && data?.Data?.length > 0) {
            jackpotId.current = data?.Data?.[0]?.JackPotId;
            subscribeToJackpotData({jackpotId: [data?.Data?.[0]?.JackPotId]});
          } else {
            setJackpotAmount(null);
          }
          break;
        case SUBSCRIBE_TO_JACKPOT_DATA:
          if (data?.Status === 1) {
            let amount = 0;
            data?.Data?.PoolGroup?.PoolList?.map(({CollectedAmount}) => {
              amount += CollectedAmount;
            });
            setJackpotAmount(amount);
            setPoolList(data?.Data?.PoolGroup?.PoolList);
            setTimeout(() => setIsJackpotLoading(false), 300);
          }
          break;
        default:
          break;
      }
    },
    [getExternalJackpotList, subscribeToJackpotData],
  );

  useEffect(() => {
    if (!!jackPotSocket) {
      setIsJackpotLoading(true);
      jackPotSocket.addEventListener('open', () => {
        getJackpots();
      });
      jackPotSocket.addEventListener('message', onMessageCb);
      jackPotSocket.addEventListener('error', ev => {
        console.log(ev, 'error jackpot socket');
      });
      jackPotSocket.addEventListener('close', ev => {
        console.log(ev, 'close jackpot socket');
        onCloseCb();
      });
    }

    return () => {
      unsubscribeFromJackpotData();
      jackPotSocket.close();
    };
  }, [jackPotSocket]);

  return {jackpotAmount, poolList, isJackpotLoading};
};

export default useJackpotSocket;
