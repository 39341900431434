export const LIVE_GAME_DETAILED_STATS_HEADER_ITEMS = [
  {
    id: 1,
    tab: 'gameVisualization',
    icon: require('../Assets/Icons/Globals/gameVisualization.svg').default,
    active_icon: require('../Assets/Icons/Globals/gameVisualization_active.svg')
      .default,
  },
  {
    id: 2,
    tab: 'H2H',
    icon: require('../Assets/Icons/Globals/stats.svg').default,
    active_icon: require('../Assets/Icons/Globals/stats_active.svg').default,
  },
  {
    id: 3,
    tab: 'stats',
    sports: ['Soccer', 'CyberFootball'],
    icon: require('../Assets/Icons/Globals/liveGameStats.svg').default,
    active_icon: require('../Assets/Icons/Globals/liveGameStats_active.svg')
      .default,
  },
  {
    id: 4,
    tab: 'timeline',
    sports: ['Soccer', 'CyberFootball'],
    icon: require('../Assets/Icons/Globals/gameEvents.svg').default,
    active_icon: require('../Assets/Icons/Globals/gameEvents_active.svg')
      .default,
  },
  {
    id: 5,
    tab: 'liveGameStats',
    icon: require('../Assets/Icons/Globals/form.svg').default,
    active_icon: require('../Assets/Icons/Globals/form_active.svg').default,
  },
];

export const UPCOMING_GAME_DETAILED_STATS_HEADER_ITEMS = [
  {
    id: 1,
    tab: 'H2H',
    icon: require('../Assets/Icons/Globals/H2H.svg').default,
    active_icon: require('../Assets/Icons/Globals/H2H_active.svg').default,
  },
  {
    id: 2,
    tab: 'leaderboard',
    icon: require('../Assets/Icons/Globals/timeline.svg').default,
    active_icon: require('../Assets/Icons/Globals/timeline_active.svg').default,
  },
  {
    id: 3,
    tab: 'teamsGeneralStats',
    icon: require('../Assets/Icons/Globals/liveGameStats.svg').default,
    active_icon: require('../Assets/Icons/Globals/liveGameStats_active.svg')
      .default,
  },
];

export const FIXED_GAME_STATS = {
  score_set1: {
    team1_value: 0,
    team2_value: 0,
  },
  yellow_card: {
    team1_value: 0,
    team2_value: 0,
  },
  red_card: {
    team1_value: 0,
    team2_value: 0,
  },
  corner: {
    team1_value: 0,
    team2_value: 0,
  },
  penalty: {
    team1_value: 0,
    team2_value: 0,
  },
};
