import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {
  getWssSocket,
  getShowBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setActiveFilterParams} from '../../../Redux/SportSlice';
import {
  getSubIds,
  getSelectedGameIds,
} from '../../../Redux/SportSlice/SportSelectors';

import Markets from './Markets';
import ScoreInfo from './ScoreInfo';
import Scoring from '../../Scoring';

import {useScreenSize} from '../../../Hooks';

import {getSportId} from '../../../Utils/GetSportId';
import {checkIsToday, getGameStartTime} from '../../../Utils/TimeConvertors';

import {
  FOOTBALL_ALIAS,
  MARKETS_HANDICAP,
  FILTERS_LIVE_CALENDAR,
  FILTERS_UPCOMING_MATCHES_NAME,
  MARKETS_OUTRIGHT,
} from '../../../Constants/MatchFilters';
import {
  MARKETS_TOTALS,
  MARKETS_WINNER,
  MARKETS_DOUBLE_CHANCE,
  MARKETS_BOTH_TEAM_TO_SCORE,
} from '../../../Constants/MatchFilters';
import {Flags} from '../../../Constants/Flags';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';

import {ReactComponent as MatchStatsIcon} from '../../../Assets/Icons/Globals/matchStats.svg';
import {ReactComponent as MoreMarketsIcon} from '../../../Assets/Icons/Globals/moreMarkets.svg';

import './index.scss';

import {DROPDOWN_MARKETS_NAMES} from '../../../Constants/FixedMarketFiltersArray';

const GameItem = ({
  setGames,
  gameItem,
  gameState,
  isLast = false,
  isFirst = false,
  current_game_time,
  isGrouped = false,
  getMarketColsCount,
  current_game_last_set,
  withBackground = false,
  showLineSeparator = false,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const subIds = useSelector(getSubIds);
  const wssSocket = useSelector(getWssSocket);
  const showBetSlip = useSelector(getShowBetSlip);
  const selectedGameIds = useSelector(getSelectedGameIds);

  const [statsPasses, setStatsPasses] = useState(gameItem?.stats?.passes);
  const [passTeam, setPassTeam] = useState(gameItem?.info?.pass_team);
  const [isBlocked, setIsBlocked] = useState(false);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(
    current_game_last_set,
  );
  const [isGameLive, setIsGameLive] = useState(!!gameItem?.is_live);
  const [gameStartTime, setGameStartTime] = useState(null);
  const [currentGameState, setCurrentGameState] = useState(gameState);
  const [team1score, setTeam1score] = useState(gameItem?.info?.score1);
  const [team2score, setTeam2score] = useState(gameItem?.info?.score2);
  const [currentMarkets, setCurrentMarkets] = useState(null);
  const [currentGameTime, setCurrentGameTime] = useState(current_game_time);
  const [currentMarketsCount, setCurrentMarketsCount] = useState(null);
  const [team1RedCards, setTeam1RedCards] = useState(
    gameItem?.stats?.red_card?.team1_value,
  );
  const [team2RedCards, setTeam2RedCards] = useState(
    gameItem?.stats?.red_card?.team2_value,
  );
  const getDayParam = getParam.get('day');
  const sportParam = getParam.get('sport');
  const getMarketParam = getParam.get('market');
  const getMarketFilterParam = getParam.get('marketFilter');

  const isFootball = useMemo(() => sportParam === FOOTBALL_ALIAS, [sportParam]);

  const sportId = useMemo(() => getSportId(sportParam), [sportParam]);

  const selectedGameId = useMemo(() => getParam.get('sportGameId'), [getParam]);

  // TODO do not delete need to handle
  const isSpecialBet = useMemo(
    () => gameItem?.show_type === MARKETS_OUTRIGHT,
    [gameItem?.show_type],
  );

  const isDisabled = useMemo(
    () => !currentMarketsCount || isBlocked,
    [currentMarketsCount, isBlocked],
  );

  const startTime = useMemo(
    () => getGameStartTime(gameStartTime),
    [gameStartTime],
  );
  const isToday = useMemo(() => checkIsToday(gameStartTime), [gameStartTime]);

  const minutesBeforeGame = useMemo(
    () => Math.round((new Date(gameStartTime * 1000) - new Date()) / 1000),
    [gameStartTime],
  );

  const navigateToGame = useCallback(() => {
    if (!isDisabled) {
      getParam.delete('betBuilderId');
      getParam.set('sportGameId', gameItem?.id);
      setGetParam(getParam);
      // navigate({
      //   pathname: location?.pathname,
      //   search: createSearchParams({
      //     game: gameItem?.id,
      //     ...(getMarketParam ? {market: getMarketParam} : {}),
      //     ...(getDayParam ? {day: getDayParam} : {}),
      //   }).toString(),
      // });
    }
  }, [isDisabled, getParam, gameItem?.id, setGetParam]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);
      if (data?.data?.[subIds?.games]) {
        const regionObject =
          data?.data?.[subIds?.games]?.sport?.[sportId]?.region;

        for (const region in regionObject) {
          const competitionObject = regionObject[region]?.competition;
          for (const competition in competitionObject) {
            const gameObject =
              competitionObject[competition]?.game?.[gameItem?.id];

            if (competitionObject[competition]?.game?.[gameItem?.id] === null) {
              setGames(prev => {
                if (prev?.length === 1) {
                  dispatch(setActiveFilterParams(''));
                  navigate(`/sports/${FILTERS_UPCOMING_MATCHES_NAME}`);
                } else {
                  return prev?.filter(
                    currentItem => currentItem?.id !== gameItem?.id,
                  );
                }
              });
              return;
            }

            if (gameObject?.market) {
              // HANDLE MARKET CHANGES
              const prevMarkets = {...currentMarkets};

              for (const market in gameObject?.market) {
                if (gameObject?.market?.[market] === null) {
                  // HANDLE MARKET DELETING CASE
                  delete prevMarkets[market];
                } else if (
                  typeof prevMarkets?.[market] === 'undefined' ||
                  Object.keys(prevMarkets?.[market])?.length === 0
                ) {
                  // HANDLE MARKET ADDING CASE
                  prevMarkets[market] = gameObject?.market?.[market];
                } else if (prevMarkets?.[market]) {
                  // HANDLE MARKET PRICE CHANGING
                  const changedEventObject =
                    gameObject?.market?.[market]?.event;

                  const prevEvents = {...prevMarkets[market].event};

                  for (const changedEvent in changedEventObject) {
                    if (changedEventObject[changedEvent] === null) {
                      // HANDLE EVENT DELETING CASE
                      delete prevEvents[changedEvent];
                    } else if (
                      typeof prevEvents[changedEvent] === 'undefined' ||
                      Object.keys(prevEvents[changedEvent])?.length === 0
                    ) {
                      // HANDLE EVENT ADDING CASE
                      prevEvents[changedEvent] =
                        gameObject?.market?.[market].event[changedEvent];
                    } else if (prevEvents?.[changedEvent]) {
                      // HANDLE EVENT PRICE CHANGING
                      const lastPrice =
                        currentMarkets[market]?.event?.[changedEvent]?.price;
                      prevEvents[changedEvent] = {
                        ...(prevEvents?.[changedEvent] || {}),
                        ...(changedEventObject?.[changedEvent] || {}),
                        ...(+lastPrice !==
                        +changedEventObject?.[changedEvent]?.price
                          ? {
                              lastPrice:
                                currentMarkets[market]?.event?.[changedEvent]
                                  ?.price,
                            }
                          : {}),
                      };
                    }
                  }
                  prevMarkets[market].event = prevEvents;
                }
              }
              setCurrentMarkets(prevMarkets);
            }

            // HANDLE MARKET COUNT CHANGES
            if (typeof gameObject?.markets_count !== 'undefined') {
              setCurrentMarketsCount(gameObject?.markets_count);
            }

            // HANDLE BLOCK CHANGES
            if (typeof gameObject?.is_blocked !== 'undefined') {
              setIsBlocked(!!gameObject?.is_blocked);
            }
            if (typeof gameObject?.info?.current_game_state !== 'undefined') {
              setCurrentGameState(gameObject?.info?.current_game_state);
            }
            if (typeof gameObject?.is_live !== 'undefined') {
              setIsGameLive(!!gameObject?.is_live);
            }
            if (typeof gameObject?.info?.score1 !== 'undefined') {
              setTeam1score(gameObject?.info?.score1);
            }
            if (typeof gameObject?.info?.current_game_time !== 'undefined') {
              setCurrentGameTime(gameObject?.info?.current_game_time);
            }
            if (typeof gameObject?.info?.score2 !== 'undefined') {
              setTeam2score(gameObject?.info?.score2);
            }
            if (
              typeof gameObject?.start_ts !== 'undefined' &&
              !!gameObject?.start_ts
            ) {
              setGameStartTime(gameObject?.start_ts);
            }
            if (
              typeof gameObject?.stats?.red_card?.team1_value !== 'undefined' &&
              !!gameObject?.stats?.red_card?.team1_value
            ) {
              setTeam1RedCards(gameObject?.stats?.red_card?.team1_value);
            }
            if (
              typeof gameObject?.stats?.red_card?.team2_value !== 'undefined' &&
              !!gameObject?.stats?.red_card?.team2_value
            ) {
              setTeam2RedCards(gameObject?.stats?.red_card?.team2_value);
            }
            if (
              typeof gameObject?.stats?.[`score_${currentGameState}`] !==
              'undefined'
            ) {
              setCurrentGameLastSet(prevState => ({
                ...prevState,
                ...gameObject?.stats?.[`score_${currentGameState}`],
              }));
            }

            if (typeof gameObject?.stats?.passes !== 'undefined') {
              setStatsPasses(prevState => ({
                ...prevState,
                ...gameObject?.stats?.passes,
              }));
            }

            if (
              typeof gameObject?.info?.pass_team !== 'undefined' &&
              !!gameObject?.info?.pass_team
            ) {
              setPassTeam(gameObject?.info?.pass_team);
            }
          }
        }
      }
    },
    [
      sportId,
      setGames,
      dispatch,
      navigate,
      gameItem?.id,
      subIds?.games,
      currentMarkets,
      currentGameState,
    ],
  );

  useEffect(() => {
    setCurrentMarkets(gameItem?.market || {});
    setCurrentMarketsCount(gameItem?.markets_count || 0);
    setIsBlocked(!!gameItem?.is_blocked);
    setGameStartTime(gameItem?.start_ts);
    setCurrentGameState(gameState);
  }, [
    gameState,
    gameItem?.market,
    gameItem?.start_ts,
    gameItem?.is_blocked,
    gameItem?.markets_count,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const marketsData = useMemo(
    () =>
      DROPDOWN_MARKETS_NAMES?.[sportParam] ||
      DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'],
    [sportParam],
  );

  return (
    <div
      onClick={navigateToGame}
      className={`gameItemContainer cursor-pointer ${
        width < BREAKPOINT_XS ? 'mobileBorderSeparators pb-sm' : `desktop`
      } ${
        +selectedGameId === +gameItem?.id
          ? 'yellowBackground'
          : withBackground
          ? 'softGrayBackground'
          : 'whiteBackground'
      } ${
        isFirst ? 'rounded-top-borders' : isLast ? 'rounded-bottom-borders' : ''
      } ${selectedGameIds?.includes?.(gameItem?.id) && 'selectedBorder'}`}>
      <div className="column paddingVertical-13 gap-5">
        <ScoreInfo
          isToday={isToday}
          gameItem={{
            ...gameItem,
            info: {
              ...gameItem?.info,
              score1: team1score,
              score2: team2score,
              pass_team: passTeam,
              current_game_time: currentGameTime,
              current_game_state: currentGameState,
            },
            stats: {
              ...gameItem?.stats,
              passes: statsPasses,
              [`score_${currentGameState}`]: currentGameLastSet,
            },
          }}
          startTime={startTime}
          isGameLive={isGameLive}
          minutesBeforeGame={minutesBeforeGame}
        />
        <div className="row items-center px-sm gap-5">
          <div className="row gameItemWrapper justify-between overflow-hidden gap-3">
            <div className="statsContainer overflow-hidden">
              <div className="flex column">
                <div className="flex items-center">
                  {location?.pathname?.includes(
                    `/${FILTERS_LIVE_CALENDAR}`,
                  ) && (
                    <div className="rowCenter ml-xs">
                      {Flags?.[gameItem?.region_alias] && (
                        <img
                          width={16}
                          alt={gameItem?.region_alias}
                          src={Flags?.[gameItem?.region_alias]}
                        />
                      )}
                      <span
                        className="text-caption-small bold-500 textWithDotes ml-xs grayText"
                        style={{
                          ...{
                            maxWidth:
                              width - 20 - 190 - 52 - 40 > 50
                                ? width - 20 - 190 - 52 - 40
                                : 50,
                          },
                        }}>
                        {gameItem?.competition_name}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex column overflow-hidden gap-5">
                  <div className="row items-center gap-5 overflow-hidden">
                    <img
                      width={15}
                      height={15}
                      alt={gameItem?.team1_id}
                      src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                        gameItem?.team1_id / 2000,
                      )}/${gameItem?.team1_id}.png`}
                    />
                    <span
                      // style={{maxWidth: 0.32 * width}}
                      className={`font-12 blackText ellipsis teamNames bold-${
                        +team1score >= +team2score ? '700' : '500'
                      }`}>
                      {gameItem?.team1_name}
                    </span>
                    {!!team1RedCards && (
                      <div
                        style={{width: 10, height: 13, borderRadius: 3}}
                        className="redBackground flex items-center justify-center">
                        {!!team1RedCards && +team1RedCards > 1 && (
                          <span className="font-9 whiteText bold-700">
                            {team1RedCards}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {!!gameItem?.team2_name && (
                    <div className="row items-center gap-5 overflow-hidden">
                      <img
                        width={15}
                        height={15}
                        alt={gameItem?.team2_id}
                        src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                          gameItem?.team2_id / 2000,
                        )}/${gameItem?.team2_id}.png`}
                      />
                      <span
                        // style={{maxWidth: 0.32 * width}}
                        className={`font-13 ellipsis blackText teamNames bold-${
                          +team2score >= +team1score ? '700' : '500'
                        }`}>
                        {gameItem?.team2_name}
                      </span>
                      {!!team2RedCards && (
                        <div
                          style={{width: 10, height: 13, borderRadius: 3}}
                          className="redBackground flex items-center justify-center">
                          {!!team2RedCards && +team2RedCards > 1 && (
                            <span className="font-9 whiteText bold-700">
                              {team2RedCards}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div
                className={`flex column items-center pr-${
                  width < BREAKPOINT_XS ? 'sm' : 'xl'
                } gap-5`}>
                {!!isGameLive && (
                  <Scoring
                    game={{
                      ...gameItem,
                      info: {
                        ...gameItem?.info,
                        score1: team1score,
                        score2: team2score,
                        pass_team: passTeam,
                        current_game_state: currentGameState,
                      },
                      stats: {
                        ...gameItem?.stats,
                        passes: statsPasses,
                        [`score_${currentGameState}`]: currentGameLastSet,
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {isSpecialBet ? (
            <div className="row items-center gap-6">
              <span className="bold-600 font-12 blackText">
                {t('outright')}
              </span>
              <MoreMarketsIcon />
            </div>
          ) : (
            <div className="row items-center">
              <div className="row gap-30 items-end">
                {marketsData?.map((item, index) => (
                  <div
                    className={`row gap-5 sm-hide ${
                      !!getMarketParam && 'hide'
                    } ${
                      width < (showBetSlip ? 1640 : 1320) &&
                      marketsData?.length > 3 &&
                      index === marketsData?.length - 1 &&
                      'hide'
                    } ${
                      width < 1200 &&
                      marketsData?.length > 3 &&
                      index === marketsData?.length - 2 &&
                      'hide'
                    } ${showBetSlip && width < 1450 && index > 2 && 'hide'} ${
                      showBetSlip && width < 1330 && index > 1 && 'hide'
                    } ${showBetSlip && width < 1200 && index > 0 && 'hide'}`}
                    key={item?.title}>
                    <Markets
                      gameItem={gameItem}
                      isDisabled={isDisabled}
                      displayKey={item?.displayKey}
                      marketFilter={item?.subTitle}
                      currentMarkets={currentMarkets}
                      marketColsCount={getMarketColsCount(
                        item?.displayKey,
                        item?.type,
                      )}
                    />
                  </div>
                ))}
                {/*<div*/}
                {/*  className={`flex gap-5 sm-hide ${*/}
                {/*    !!getMarketParam && 'hide'*/}
                {/*  }`}>*/}
                {/*  <Markets*/}
                {/*    gameItem={gameItem}*/}
                {/*    isDisabled={isDisabled}*/}
                {/*    displayKey={MARKETS_WINNER}*/}
                {/*    currentMarkets={currentMarkets}*/}
                {/*    marketColsCount={getMarketColsCount(MARKETS_WINNER)}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*{isFootball && (*/}
                {/*  <div*/}
                {/*    className={`flex gap-5 sm-hide ${*/}
                {/*      (!!getMarketParam ||*/}
                {/*        (!!selectedGameId && width >= BREAKPOINT_MD) ||*/}
                {/*        (!!showBetSlip &&*/}
                {/*          width >= BREAKPOINT_XS &&*/}
                {/*          width < BREAKPOINT_MD)) &&*/}
                {/*      'hide'*/}
                {/*    }`}>*/}
                {/*    <Markets*/}
                {/*      gameItem={gameItem}*/}
                {/*      isDisabled={isDisabled}*/}
                {/*      currentMarkets={currentMarkets}*/}
                {/*      displayKey={MARKETS_DOUBLE_CHANCE}*/}
                {/*      marketColsCount={getMarketColsCount(*/}
                {/*        MARKETS_DOUBLE_CHANCE,*/}
                {/*      )}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{isFootball && (*/}
                {/*  <div*/}
                {/*    className={`flex gap-5 sm-hide ${*/}
                {/*      (!!getMarketParam ||*/}
                {/*        (!!selectedGameId && width >= BREAKPOINT_MD) ||*/}
                {/*        (!!showBetSlip &&*/}
                {/*          width >= BREAKPOINT_XS &&*/}
                {/*          width < BREAKPOINT_LG)) &&*/}
                {/*      'hide'*/}
                {/*    }`}>*/}
                {/*    <Markets*/}
                {/*      gameItem={gameItem}*/}
                {/*      isDisabled={isDisabled}*/}
                {/*      currentMarkets={currentMarkets}*/}
                {/*      displayKey={MARKETS_BOTH_TEAM_TO_SCORE}*/}
                {/*      marketColsCount={getMarketColsCount(*/}
                {/*        MARKETS_BOTH_TEAM_TO_SCORE,*/}
                {/*      )}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*<div*/}
                {/*  className={`flex gap-5 sm-hide ${*/}
                {/*    (!!getMarketParam ||*/}
                {/*      (!!selectedGameId && width >= BREAKPOINT_MD) ||*/}
                {/*      (!!showBetSlip &&*/}
                {/*        !!isFootball &&*/}
                {/*        width >= BREAKPOINT_XS &&*/}
                {/*        width < BREAKPOINT_LG)) &&*/}
                {/*    'hide'*/}
                {/*  }`}>*/}
                {/*  <Markets*/}
                {/*    gameItem={gameItem}*/}
                {/*    isDisabled={isDisabled}*/}
                {/*    displayKey={MARKETS_HANDICAP}*/}
                {/*    currentMarkets={currentMarkets}*/}
                {/*    marketColsCount={getMarketColsCount(MARKETS_HANDICAP)}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className={`flex gap-5 md-hide sm-hide ${*/}
                {/*    (!!getMarketParam ||*/}
                {/*      (!!selectedGameId && width > BREAKPOINT_MD) ||*/}
                {/*      (!!showBetSlip &&*/}
                {/*        width >= BREAKPOINT_XS &&*/}
                {/*        width < BREAKPOINT_LG)) &&*/}
                {/*    'hide'*/}
                {/*  }`}>*/}
                {/*  <Markets*/}
                {/*    gameItem={gameItem}*/}
                {/*    isDisabled={isDisabled}*/}
                {/*    displayKey={MARKETS_TOTALS}*/}
                {/*    currentMarkets={currentMarkets}*/}
                {/*    marketColsCount={getMarketColsCount(MARKETS_TOTALS)}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className={`flex gap-5 ${!getMarketParam && 'hide'}`}>
                  <Markets
                    gameItem={gameItem}
                    isDisabled={isDisabled}
                    currentMarkets={currentMarkets}
                    marketFilter={getMarketFilterParam}
                    displayKey={
                      marketsData?.find(item => item?.title === getMarketParam)
                        ?.displayKey
                    }
                    marketColsCount={getMarketColsCount(
                      marketsData?.find(item => item?.title === getMarketParam)
                        ?.displayKey,
                      marketsData?.find(item => item?.title === getMarketParam)
                        ?.type,
                    )}
                  />
                </div>
              </div>
              <div className="column gap-5 justify-center pl-sm">
                <MoreMarketsIcon />
                {!isDisabled && <MatchStatsIcon />}
              </div>
            </div>
          )}
        </div>
        {!isGrouped &&
          !location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) && (
            <div className="flex items-center gap-5 px-sm">
              {Flags?.[gameItem?.region_alias] && (
                <img
                  alt="flag"
                  className="flag"
                  style={{objectFit: 'cover'}}
                  src={Flags?.[gameItem?.region_alias]}
                />
              )}
              <span className="font-11 bold-700 blackText">
                {gameItem?.competition_name}
              </span>
            </div>
          )}
      </div>

      {/*{width > BREAKPOINT_XS &&*/}
      {/*  isGrouped &&*/}
      {/*  showLineSeparator &&*/}
      {/*  !+selectedGameId && (*/}
      {/*    <div className="marketsCountContainer px-xl">*/}
      {/*      /!*  <div className="flex justify-end relative marketsCountWrapper">*!/*/}
      {/*      /!*<span className="blueText bold-700 text-caption-small">*!/*/}
      {/*      /!*  {!!currentMarketsCount && '+'}*!/*/}
      {/*      /!*  {currentMarketsCount}*!/*/}
      {/*      /!*</span>*!/*/}
      {/*      /!*  </div>*!/*/}
      {/*      <div className="full-width relative line"></div>*/}
      {/*    </div>*/}
      {/*  )}*/}
    </div>
  );
};

export default memo(GameItem);
