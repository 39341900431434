import moment from 'moment/moment';

import {
  DATE_DEFAULT_FORMAT,
  DATE_FORMAT_WITH_HOURS_MINUTES,
} from '../Constants/Globals';

import i18next from 'i18next';

export const formatDate = (value, format = DATE_DEFAULT_FORMAT) =>
  moment(value)?.format(format);

export const formatUnixDate = (
  value,
  format = DATE_FORMAT_WITH_HOURS_MINUTES,
) => moment?.unix?.(value)?.format?.(format);

export const getDateColor = date => ({
  color: '#000000',
  // date?.indexOf(i18next.t('todayUpper')) > -1
  //   ? 'var(--lightGreen)'
  //   : date?.indexOf(i18next.t('tomorrowUpper')) > -1
  //   ? 'var(--darkAqua)'
  //   : 'gray',
});

export const isoStringTODate = isoString => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
};
