import React, {memo, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SlotCounter from 'react-slot-counter';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getCurrency} from '../../Redux/PartnerSlice/PartnerSelectors';

import {useJackpotSocket, useScreenSize} from '../../Hooks';

import {isoStringTODate} from '../../Utils/DateFormatter';
import {fixedNumberWithoutRound} from '../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../Utils/BigNumbersPrettier';

import {
  BREAKPOINT_XS,
  NUMBERS_FORMAT_WITH_COMMA_REGEX,
} from '../../Constants/Globals';
import CarouselSkeletons from '../UI/Skeletons/CarouselSkeletons';

const Jackpot = ({title = 'AMUSNET'}) => {
  const {width} = useScreenSize();
  const {jackpotAmount, poolList, isJackpotLoading} = useJackpotSocket({
    SourceId: 0,
  });

  const [isExpanded, setExpanded] = useState(false);
  const [selectedDisplay, setSelectedDisplay] = useState('');

  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  const [showModal, setShowModal] = useState(false);

  const amount = useMemo(() => {
    const amountWithoutRounding = fixedNumberWithoutRound(
      jackpotAmount || 0,
      2,
    );
    amountWithoutRounding?.replace?.(NUMBERS_FORMAT_WITH_COMMA_REGEX, ',');
    return bigNumbersPrettier(amountWithoutRounding);
  }, [jackpotAmount]);

  const titleFontSize = useMemo(
    () =>
      width > 0 && width < 599 ? 'text-body2' : width >= 599 && 'text-body1',
    [width],
  );

  const amountFontSize = useMemo(
    () =>
      width > 0 && width < 599
        ? 'text-h5'
        : width >= 599 && width <= 768
        ? 'text-h4'
        : width > 768 && width <= 1024
        ? 'text-h3'
        : width > 1024
        ? 'text-h2'
        : 'text-h1',
    [width],
  );

  return isJackpotLoading ? (
    <CarouselSkeletons
      minHeight={100}
      currentHeight={100}
      baseColor="var(--lightBlue)"
      highlightColor="var(--appBlue)"
    />
  ) : (
    <div
      key={amountFontSize}
      className="appJackpotContainer flex items-center justify-center column">
      <span
        className={`font-${
          width < BREAKPOINT_XS ? 14 : 20
        } yellowText bold-700`}>
        {t(title)}
      </span>
      <div className="flex row items-center gap-10">
        <SlotCounter
          value={amount}
          charClassName={`${amountFontSize} yellowText font-${
            width < 400 ? 30 : width < BREAKPOINT_XS ? 35 : 50
          } bold-700`}
          valueClassName={`${amountFontSize} yellowText font-${
            width < 400 ? 30 : width < BREAKPOINT_XS ? 35 : 50
          } bold-700`}
          separatorClassName={`${amountFontSize} yellowText jackpotGradient font-${
            width < 400 ? 30 : width < BREAKPOINT_XS ? 35 : 50
          } bold-700`}
        />
        <span
          className={`${amountFontSize} yellowText font-${
            width < 400 ? 30 : width < BREAKPOINT_XS ? 35 : 50
          } bold-700 jackpotGradient`}>
          {currency}
        </span>
      </div>
      <div
        className={`flex ${
          width < BREAKPOINT_XS ? 'column' : 'row'
        } items-center full-width justify-between gap-12`}
        style={{
          marginTop: 20,
          ...(width < BREAKPOINT_XS
            ? {
                display: 'grid',
                gridTemplateColumns: `repeat(${
                  !!selectedDisplay ? 3 : 2
                }, 1fr)`,
              }
            : {}),
        }}>
        {(poolList || [])
          ?.sort?.((a, b) => b?.Order - a?.Order)
          ?.map(pool => {
            return (
              <div
                className={`poolItem flex-1 ${
                  width < BREAKPOINT_XS && 'full-width'
                }`}
                style={{
                  backgroundColor:
                    (width < BREAKPOINT_XS &&
                      selectedDisplay === pool?.DisplayName) ||
                    (!(width < BREAKPOINT_XS) && !!isExpanded)
                      ? 'var(--appYellow)'
                      : '#17315b',
                  ...(width < BREAKPOINT_XS
                    ? selectedDisplay === pool?.DisplayName
                      ? {gridColumn: '1 / -1', order: -1}
                      : {}
                    : {}),
                }}
                key={pool?.DisplayName}>
                <div
                  onClick={() => {
                    if (width < BREAKPOINT_XS) {
                      if (selectedDisplay === pool?.DisplayName) {
                        setSelectedDisplay('');
                      } else {
                        setSelectedDisplay(pool?.DisplayName);
                      }
                    } else {
                      setExpanded(prevState => !prevState);
                    }
                  }}
                  className="cursor-pointer">
                  <div
                    className={`flex column items-${
                      width < BREAKPOINT_XS &&
                      selectedDisplay === pool?.DisplayName
                        ? 'end'
                        : 'center'
                    } infoContainer`}>
                    <span
                      className={`text-uppercase font-${
                        width < 400 ? 12 : width < BREAKPOINT_XS ? 15 : 15
                      } bold-700 ${
                        (width < BREAKPOINT_XS &&
                          selectedDisplay === pool?.DisplayName) ||
                        (!(width < BREAKPOINT_XS) && !!isExpanded)
                          ? 'blueText'
                          : 'yellowText'
                      }`}>
                      {pool?.DisplayName}
                    </span>
                    <div className="flex gap-5">
                      <span
                        className={`font-${
                          width < 400 ? 12 : width < BREAKPOINT_XS ? 15 : 15
                        } bold-700 ${
                          (width < BREAKPOINT_XS &&
                            selectedDisplay === pool?.DisplayName) ||
                          (!(width < BREAKPOINT_XS) && !!isExpanded)
                            ? 'blueText'
                            : 'jackpotGradient'
                        }`}>
                        {pool?.CollectedAmount?.toLocaleString?.()}
                      </span>
                      <span
                        className={`font-${
                          width < 400 ? 12 : width < BREAKPOINT_XS ? 15 : 15
                        } bold-700 ${
                          (width < BREAKPOINT_XS &&
                            selectedDisplay === pool?.DisplayName) ||
                          (!width < BREAKPOINT_XS && !!isExpanded)
                            ? 'blueText'
                            : 'whiteText'
                        }`}>
                        {currency}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    width < BREAKPOINT_XS
                      ? pool?.DisplayName !== selectedDisplay && 'hidden'
                      : !isExpanded && 'hidden'
                  } winsInfoContainer`}>
                  <div className="full-width line"></div>
                  <div className="flex column gap-5">
                    <div className="flex row justify-between winningItem gap-3">
                      <div className="flex column">
                        <span className={`yellowText bold-600 font-13`}>
                          {t('LargestWinner')}
                        </span>
                        <span className="font-13 bold-600 whiteText">
                          {pool?.JackPotPoolStat?.BiggestJackpotWin?.Amount?.toLocaleString?.()}
                        </span>
                      </div>
                      <span className="font-13 bold-600 date">
                        {isoStringTODate(
                          pool?.JackPotPoolStat?.BiggestJackpotWin
                            ?.CreationDate,
                        )}
                      </span>
                    </div>
                    <div className="flex row justify-between winningItem">
                      <div className="flex column">
                        <span className={`yellowText bold-600 font-13`}>
                          {t('LastWinner')}
                        </span>
                        <span className="font-13 bold-600 whiteText">
                          {pool?.JackPotPoolStat?.LastJackPotWin?.Amount?.toLocaleString?.()}
                        </span>
                      </div>
                      <span className="font-13 bold-600 date">
                        {isoStringTODate(
                          pool?.JackPotPoolStat?.LastJackPotWin?.CreationDate,
                        )}
                      </span>
                    </div>
                    <div className="winningItem">
                      <div className="flex column">
                        <span className={`yellowText bold-600 font-13`}>
                          {t('NumberOfWinners')}
                        </span>
                        <span className="font-13 bold-600 whiteText">
                          {pool?.TotalWinCount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default memo(Jackpot);
