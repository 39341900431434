import React, {memo, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';
import {useSelector} from 'react-redux';

import {
  getCurrency,
  getTaxPercentage,
  getMaxOddForMultipleBet,
} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {getBonusInfoData} from '../../../../Redux/BettingSlice/BettingSelectors';

import {
  getCombinations,
  calculateSystemBet,
} from '../../../../Utils/CombinationsGenerator';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {
  SINGLE_BET,
  SYSTEM_BET,
  MULTIPLE_BET,
} from '../../../../Constants/Betting';

import CalculationRow from '../CalculationRow';

import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as Chevron} from '../../../../Assets/Icons/Globals/chevronBottom.svg';
import {ReactComponent as WinBoosterIcon} from '../../../../Assets/Icons/Globals/winBooster.svg';

const CalculationsBlock = ({
  odds,
  betType,
  stakeAmount,
  eventsCount,
  allEventsData,
  systemBetCount,
}) => {
  const {t} = useTranslation();
  const currency = useSelector(getCurrency);
  const taxPercentage = useSelector(getTaxPercentage);
  const bonusInfoData = useSelector(getBonusInfoData);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const [systemBetData, setSystemBetData] = useState({});
  const [showRestCalculations, setShowRestCalculations] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded: showRestCalculations,
  });

  const oddsArray = useMemo(
    () => allEventsData?.map(event => event?.price),
    [allEventsData],
  );

  const totalStakeForSystemBet = useMemo(
    () =>
      getCombinations(allEventsData?.length, +systemBetCount)?.length *
      +stakeAmount,
    [allEventsData, stakeAmount, systemBetCount],
  );

  const possibleWinAmount = useMemo(
    () =>
      betType === SYSTEM_BET ? systemBetData?.possibleWin : +stakeAmount * odds,
    [betType, systemBetData?.possibleWin, odds, stakeAmount],
  );

  const notValidOdds = useMemo(() => {
    let notValid = [];
    bonusInfoData?.find(item => {
      notValid = oddsArray?.filter(
        odd => odd < item?.MinOdds && odds !== maxOddForMultipleBet,
      );
    });
    return notValid?.reduce((acc, curr) => curr * acc, 1);
  }, [bonusInfoData, maxOddForMultipleBet, odds, oddsArray]);

  const accumulatorBonusRowData = useMemo(() => {
    const foundBonusData = bonusInfoData?.find(item => {
      const validOddsArray = oddsArray?.filter(odd => odd >= item?.MinOdds);
      return (
        validOddsArray?.length >= item?.MinimumSelections &&
        validOddsArray?.length <= item?.MaximumSelections &&
        +stakeAmount >=
          item?.MinBetStakes?.MinStakes?.find(
            stake => stake?.Currency === currency,
          )?.Amount
      );
    });
    if (foundBonusData) {
      return {
        percent: foundBonusData?.AmountPercent,
        amount:
          ((possibleWinAmount / notValidOdds - Number(stakeAmount)) *
            +foundBonusData?.AmountPercent) /
          100,
      };
    }
  }, [
    currency,
    oddsArray,
    stakeAmount,
    notValidOdds,
    bonusInfoData,
    possibleWinAmount,
  ]);

  const taxAmount = useMemo(
    () =>
      betType === SYSTEM_BET
        ? systemBetData?.tax
        : (possibleWinAmount -
            +stakeAmount +
            (accumulatorBonusRowData?.amount || 0)) *
          (taxPercentage / 100),

    [
      betType,
      stakeAmount,
      taxPercentage,
      possibleWinAmount,
      systemBetData?.tax,
      accumulatorBonusRowData?.amount,
    ],
  );

  const finalPayout = useMemo(
    () =>
      betType === SYSTEM_BET
        ? systemBetData?.finalPayout
        : possibleWinAmount -
          taxAmount +
          (accumulatorBonusRowData?.amount || 0),
    [
      betType,
      taxAmount,
      possibleWinAmount,
      systemBetData?.finalPayout,
      accumulatorBonusRowData?.amount,
    ],
  );

  useEffect(() => {
    if (betType === SYSTEM_BET) {
      setSystemBetData(
        calculateSystemBet({
          oddsArray,
          taxPercentage,
          systemBetCount,
          stakeAmount: +stakeAmount,
        }),
      );
    }
  }, [betType, oddsArray, stakeAmount, systemBetCount, taxPercentage]);

  return (
    <div className="column gap-5 font-12 bold-700">
      {!showRestCalculations &&
        betType === MULTIPLE_BET &&
        accumulatorBonusRowData && (
          <CalculationRow
            hideCurrency
            amountContainerHeight={20}
            customStyles={{background: 'transparent'}}
            titleClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            }
            amountClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText bold-700'
            }
            amount={
              <div
                className="row items-center gap-5 overflow-hidden"
                style={{maxWidth: 155}}>
                <WinBoosterIcon
                  className="flex-shrink mt-sm"
                  style={{boxShadow: '0px 4px 4px 0px #00000040'}}
                />
                <span className={`ellipsis whiteText bold-700 font-14`}>
                  {bigNumbersPrettier(
                    fixedNumberWithoutRound(accumulatorBonusRowData?.amount),
                  )}
                </span>
              </div>
            }
            amountTextClassName={`${
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            } bold-600 font-15`}
            title={
              <span
                className={`${
                  betType === SINGLE_BET && eventsCount > 1
                    ? 'blueText'
                    : 'whiteText'
                } bold-600 font-16 flex-shrink`}>
                {t('accumulatorBonus')} ({accumulatorBonusRowData?.percent}%)
              </span>
            }
          />
        )}
      <div {...getCollapseProps()}>
        {betType === SYSTEM_BET && (
          <CalculationRow
            hideCurrency
            amountContainerHeight={20}
            customStyles={{background: 'transparent'}}
            title={
              <span
                className={`${
                  betType === SINGLE_BET && eventsCount > 1
                    ? 'blueText'
                    : 'whiteText'
                } bold-600 font-16 flex-shrink`}>
                {t('totalStake')}
              </span>
            }
            titleClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            }
            amountClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText bold-700'
            }
            amount={bigNumbersPrettier(
              fixedNumberWithoutRound(totalStakeForSystemBet),
            )}
            amountTextClassName={`${
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            } bold-600 font-15`}
          />
        )}
        <CalculationRow
          title={
            <span
              className={`${
                betType === SINGLE_BET && eventsCount > 1
                  ? 'blueText'
                  : 'whiteText'
              } bold-600 font-16 flex-shrink`}>
              {t('possibleWin')}
            </span>
          }
          titleClassName={
            betType === SINGLE_BET && eventsCount > 1
              ? 'blueText'
              : 'whiteText font-16 bold-600'
          }
          hideCurrency
          amountContainerHeight={20}
          customStyles={{background: 'transparent'}}
          amountClassName={
            betType === SINGLE_BET && eventsCount > 1
              ? 'blueText'
              : 'whiteText bold-700'
          }
          amount={
            betType === SYSTEM_BET
              ? possibleWinAmount
              : bigNumbersPrettier(fixedNumberWithoutRound(possibleWinAmount))
          }
          amountTextClassName={`${
            betType === SINGLE_BET && eventsCount > 1 ? 'blueText' : 'whiteText'
          } bold-600 font-15`}
        />
        <CalculationRow
          hideCurrency
          amountContainerHeight={20}
          customStyles={{background: 'transparent'}}
          amountClassName={
            betType === SINGLE_BET && eventsCount > 1
              ? 'blueText'
              : 'whiteText bold-700'
          }
          title={
            <span
              className={`${
                betType === SINGLE_BET && eventsCount > 1
                  ? 'blueText'
                  : 'whiteText'
              } bold-600 font-16 flex-shrink`}>
              {t('tax')} ({taxPercentage}%)
            </span>
          }
          amount={
            betType === SYSTEM_BET
              ? taxAmount
              : bigNumbersPrettier(fixedNumberWithoutRound(taxAmount))
          }
          amountTextClassName={`${
            betType === SINGLE_BET && eventsCount > 1 ? 'blueText' : 'whiteText'
          } bold-600 font-15`}
        />
        {betType === MULTIPLE_BET && accumulatorBonusRowData && (
          <CalculationRow
            hideCurrency
            amountContainerHeight={20}
            customStyles={{background: 'transparent'}}
            titleClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            }
            amountClassName={
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText bold-700'
            }
            amount={
              <div
                className="row items-center gap-5 overflow-hidden"
                style={{maxWidth: 155}}>
                <WinBoosterIcon
                  className="flex-shrink mt-sm"
                  style={{boxShadow: '0px 4px 4px 0px #00000040'}}
                />
                <span className={`ellipsis whiteText bold-700 font-14`}>
                  {bigNumbersPrettier(
                    fixedNumberWithoutRound(accumulatorBonusRowData?.amount),
                  )}
                </span>
              </div>
            }
            amountTextClassName={`${
              betType === SINGLE_BET && eventsCount > 1
                ? 'blueText'
                : 'whiteText'
            } bold-600 font-15`}
            title={
              <span
                className={`${
                  betType === SINGLE_BET && eventsCount > 1
                    ? 'blueText'
                    : 'whiteText'
                } bold-600 font-16 flex-shrink`}>
                {t('accumulatorBonus')} ({accumulatorBonusRowData?.percent}%)
              </span>
            }
          />
        )}
      </div>
      <CalculationRow
        hideCurrency
        amountContainerHeight={20}
        customStyles={{background: 'transparent'}}
        title={
          <div className="row items-center gap-10 flex-shrink">
            <span
              className={`${
                betType === SINGLE_BET && eventsCount > 1
                  ? 'blueText'
                  : 'whiteText'
              } bold-600 font-16 flex-shrink`}>
              {t('finalPayout')}
            </span>
            <div
              {...getToggleProps({
                onClick: () => setShowRestCalculations(prev => !prev),
              })}>
              <div
                style={{
                  width: 25,
                  height: 25,
                  flexShrink: 0,
                  borderRadius: '50%',
                  backgroundColor:
                    showRestCalculations ||
                    (betType === SINGLE_BET && eventsCount > 1)
                      ? 'var(--appYellow)'
                      : 'transparent',
                  border: `1px solid ${
                    showRestCalculations ||
                    (betType === SINGLE_BET && eventsCount > 1)
                      ? 'transparent'
                      : 'white'
                  }`,
                }}
                className="row items-center justify-center">
                <Chevron
                  fill={
                    showRestCalculations ||
                    (betType === SINGLE_BET && eventsCount > 1)
                      ? 'var(--appBlue)'
                      : 'white'
                  }
                  style={
                    showRestCalculations ? {} : {transform: 'rotate(180deg)'}
                  }
                />
              </div>
            </div>
          </div>
        }
        titleClassName={
          betType === SINGLE_BET && eventsCount > 1 ? 'blueText' : 'whiteText'
        }
        amountClassName={
          betType === SINGLE_BET && eventsCount > 1
            ? 'blueText'
            : 'whiteText bold-700 font-18'
        }
        amountContainerClassName="justify-end"
        amount={
          <div
            className="row items-center gap-5 overflow-hidden"
            style={{maxWidth: 155}}>
            <CurrencyIcon
              width={18}
              height={10}
              className="flex-shrink"
              style={{boxShadow: '0px 4px 4px 0px #00000040'}}
            />
            <span
              style={{
                textShadow: '0px 4px 4px #000000D9',
              }}
              className={`${
                betType === SINGLE_BET && eventsCount > 1
                  ? 'blueText'
                  : 'yellowText'
              } font-14 bold-700 ellipsis`}>
              {betType === SYSTEM_BET
                ? finalPayout
                : bigNumbersPrettier(fixedNumberWithoutRound(finalPayout))}
            </span>
          </div>
        }
      />
    </div>
  );
};

export default memo(CalculationsBlock);
