import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useSearchParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {useSocket, useStorage} from '../../../Hooks';

import SportItem from './SportItem';
import SportTeamItem from './SportTeamItem';

import {AppInput, AppModal} from '../../UI';
import SearchGamesSkeletons from '../../UI/Skeletons/SearchGamesSkeletons';

import {setShowSearchModal} from '../../../Redux/AppSlice';
import {
  getWssSocket,
  getShowSearchModal,
} from '../../../Redux/AppSlice/AppSelectors';

import {FreeMode, Keyboard} from 'swiper/modules';

import {GET_SEARCHED_GAMES} from '../../../Constants/Socket';
import {FIXED_TEAMS} from '../../../Constants/SearchFixedTeams';

import {ReactComponent as Back} from '../../../Assets/Icons/Globals/back.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';
import {ReactComponent as CloseIcon} from '../../../Assets/Icons/Globals/close.svg';

const NewSearchModal = () => {
  const {getSearchedGames} = useSocket();
  const {t} = useTranslation();
  const {getSportLastSearchFromStorage, setSportLastSearchToStorage} =
    useStorage();

  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();

  const wssSocket = useSelector(getWssSocket);
  const showSearchModal = useSelector(getShowSearchModal);

  const timeOutRef = useRef(null);

  const [games, setGames] = useState([]);
  const [lastSearch, setLastSearch] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeSport, setActiveSport] = useState('Football');

  const handleInputChange = useCallback(
    (text, item) => {
      if (text?.length > 0) {
        setInputValue(text);
        clearTimeout(timeOutRef.current);
        if (item?.team1Id) {
          setLastSearch({
            alias: item?.alias,
            team1Id: item?.team1Id,
            team1Name: item?.team1Name,
            regionName: item?.regionName,
          });
          setSportLastSearchToStorage({
            alias: item?.alias,
            team1Id: item?.team1Id,
            team1Name: item?.team1Name,
            regionName: item?.regionName,
          })
            ?.then?.()
            ?.catch?.();
        }
        setIsLoading(true);
        timeOutRef.current = setTimeout(() => {
          getSearchedGames({search: text});
        }, 300);
      } else {
        setInputValue('');
        setGames([]);
      }
    },
    [getSearchedGames],
  );

  const onGamePress = useCallback(
    (gameItem, sportName) => {
      dispatch(setShowSearchModal(false));
      getParam.set('sportGameId', gameItem?.id);
      setGetParam(getParam);
    },
    [dispatch, getParam, setGetParam],
  );

  const resetData = useCallback(() => {
    setGames([]);
    setInputValue('');
    setActiveSport('Football');
  }, []);

  const renderSearchedGames = useMemo(() => {
    let gamesLength = 0;
    const render = Object.values(games).flatMap(sportItem =>
      Object.values(sportItem?.region || {}).flatMap(regionItem =>
        Object.values(regionItem?.competition || {}).flatMap(
          competitionItem => {
            const gamesList = Object.values(competitionItem?.game || {});
            gamesLength += gamesList.length;

            return gamesList.map(gameItem => (
              <SportTeamItem
                key={gameItem?.id}
                team2Id={gameItem?.team2_id}
                team1Id={gameItem?.team1_id}
                sportAlias={sportItem?.name}
                regionAlias={regionItem?.name}
                team1Name={gameItem?.team1_name}
                team2Name={gameItem?.team2_name}
                onPressHandler={() => onGamePress(gameItem, sportItem?.name)}
              />
            ));
          },
        ),
      ),
    );

    return {render, gamesLength};
  }, [games, onGamePress]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      if (data?.rid?.includes(GET_SEARCHED_GAMES)) {
        const searchParam = data?.rid?.split?.('_search=')?.[1]?.trim();

        if (
          (!searchParam?.trim?.() && !inputValue?.trim?.()) ||
          searchParam === inputValue?.trim?.()
        ) {
          setGames(data?.data?.data?.sport);
        }
        setTimeout(() => setIsLoading(false), 200);
      }
    },
    [inputValue],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    if (showSearchModal) {
      getSportLastSearchFromStorage()
        ?.then(res => {
          setLastSearch(res);
        })
        ?.catch(err => {});
    }
  }, [showSearchModal]);

  return (
    <AppModal
      maxWidth={400}
      maxHeight="80dvh"
      closeIconType={4}
      removeContentPaddings
      onAfterClose={resetData}
      isOpen={showSearchModal}
      onRequestClose={() => dispatch(setShowSearchModal(false))}
      header={
        <div className="headerContainer row flex">
          <div className="row center searchContainer flex">
            <SearchIcon width={11} height={11} fill={'var(--semiDarkBlue)'} />
            <span className="searchTitle">SEARCH</span>
          </div>
          <div
            className="closeContainer center flex cursor-pointer"
            onClick={() => dispatch(setShowSearchModal(false))}>
            <CloseIcon width={13} height={13} />
          </div>
        </div>
      }>
      <div className="searchContentContainer">
        <div
          style={{gap: 10, alignItems: 'center'}}
          className="flex row wrapperPadding">
          {Object.values(games || {})?.length > 0 && (
            <div
              className={
                'resetContainer flex items-center justify-center cursor-pointer'
              }
              onClick={resetData}>
              <Back
                width={18}
                height={18}
                className="backIcon"
                fill={'var(--white)'}
              />
            </div>
          )}
          <div style={{flex: 1}}>
            <AppInput
              type={4}
              height={40}
              inputValue={inputValue}
              onChange={handleInputChange}
              placeholder={t('searchAnyLeagueOrTeam')}
            />
          </div>
        </div>
        {Object.values(games || {})?.length > 0 || !!isLoading ? (
          <div className="searchedGamesContainerWrapper">
            {isLoading ? (
              <div
                style={{maxHeight: 'calc(80dvh - 160px)'}}
                className="contentContainerStyle scroll scroll-5">
                <Skeleton
                  count={12}
                  height={40}
                  duration={0.7}
                  borderRadius="5px"
                  baseColor={'#193a61'}
                  highlightColor={'var(--semiDarkBlue)'}
                />
              </div>
            ) : (
              <div className="">
                <div className="flex row resultsFoundContainer">
                  <SearchIcon width={11} height={11} fill={'var(--white)'} />
                  <span className="yourLastSearches">{`${
                    renderSearchedGames?.gamesLength
                  } ${t('resultsFound')}`}</span>
                </div>
                <div
                  className="searchedGamesContainer contentContainerStyle scroll scroll-5"
                  style={{maxHeight: 'calc(80dvh - 160px)'}}>
                  {renderSearchedGames?.render}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {!!lastSearch?.team1Id && (
              <div className="wrapperPadding">
                <div className="flex row yourLastSearchesContainer">
                  <SearchIcon width={11} height={11} fill={'var(--white)'} />
                  <span className="yourLastSearches">
                    {t('yourLastSearch')}
                  </span>
                </div>
                <SportTeamItem
                  team1Id={+lastSearch?.team1Id}
                  sportAlias={lastSearch?.alias}
                  team1Name={lastSearch?.team1Name}
                  regionAlias={lastSearch?.regionName}
                  onPressHandler={() =>
                    handleInputChange(lastSearch?.team1Name, lastSearch)
                  }
                />
              </div>
            )}
            <Swiper
              freeMode
              keyboard
              mousewheel
              spaceBetween={5}
              slidesPerView="auto"
              modules={[Keyboard, FreeMode]}
              className="swiperContainer">
              {Object.keys(FIXED_TEAMS || {})?.map((item, index) => (
                <SwiperSlide key={index}>
                  <SportItem
                    title={item}
                    activeSport={activeSport}
                    setActiveSport={setActiveSport}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="wrapperPadding listContainer">
              <span className="yourLastSearches" style={{paddingBottom: 10}}>
                {t('topTeams')}
              </span>
              <div
                className="column scroll-5 scroll"
                style={{gap: 5, maxHeight: 'calc(80dvh - 270px)'}}>
                {(FIXED_TEAMS?.[activeSport]?.teams || [])?.map(item => (
                  <SportTeamItem
                    key={item?.team1Id}
                    team1Id={item?.team1Id}
                    sportAlias={item?.alias}
                    team1Name={item?.team1Name}
                    team2Name={item?.team2Name}
                    regionAlias={item?.regionName}
                    onPressHandler={() =>
                      handleInputChange(item?.team1Name, item)
                    }
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </AppModal>
  );
};

export default NewSearchModal;
