import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {FreeMode, Keyboard} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useDispatch, useSelector} from 'react-redux';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import './index.scss';

import {getUser} from '../../Redux/UserSlice/UserSelectors';
import {setShowCasinoSearchModal} from '../../Redux/CasinoSlice';
import {setShowBetSlip, setShowSearchModal} from '../../Redux/AppSlice';
import {getIsLoggedIn, getShowBetSlip} from '../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useSocket} from '../../Hooks';

import logo from '../../Assets/Layout/HeaderLogo.png';
import LasVegasLogo from '../../Assets/Layout/LasVegasLogo.png';
import leGrandeCasino from '../../Assets/Layout/leGrandeCasinoWhite.png';
import palmBeachCasino from '../../Assets/Layout/palmBeachCasinoWhite.png';
import {ReactComponent as UserIcon} from '../../Assets/Icons/Globals/user.svg';
import {ReactComponent as CrownIcon} from '../../Assets/Icons/Globals/crown.svg';
import {ReactComponent as MyBetsIcon} from '../../Assets/Icons/Globals/myBets.svg';
import {ReactComponent as SearchIcon} from '../../Assets/Icons/Globals/search.svg';
import {ReactComponent as DownArrow} from '../../Assets/Icons/Globals/downTriangle.svg';
import {ReactComponent as TriangleTop} from '../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as PromotionsIcon} from '../../Assets/Icons/Globals/gift.svg';

import {ReactComponent as ArrowUpIcon} from '../../Assets/Icons/Globals/arrowUp.svg';
import {ReactComponent as ArrowDownIcon} from '../../Assets/Icons/Globals/arrowDown.svg';

import {
  HEADER_FILTERS,
  FILTERS_TODAY_NAME,
  HEADER_FILTERS_SPORTS,
} from '../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppButton, AppLanguageChooser} from '../UI';

import DepositButton from './DepositButton';
import MenuDropDown from '../Home/MenuDropDown';
import LoggedOutContainer from './LoggedOutContainer';
import CasinoFixedGames from '../Casino/CasinoFixedGames';
import {GET_ALL_SPORTS} from '../../Constants/Socket';

const Header = ({showTabs = true, showSearch = true}) => {
  const params = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const {getSports} = useSocket();
  const [getParam, setGetParam] = useSearchParams();

  const mainMenuDropDownContainerRef = useRef(null);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);

  const [randomCasinoImageIndex, setRandomCasinoImageIndex] = useState(0);
  const [isMainMenuDropDownOpen, setIsMainMenuDropDownOpen] = useState(false);

  const onSearchClick = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        location?.pathname?.includes('casino')
          ? setShowCasinoSearchModal(true)
          : setShowSearchModal(true),
      );
    },
    [dispatch, location?.pathname],
  );

  const ArrowIcon = isMainMenuDropDownOpen ? ArrowUpIcon : ArrowDownIcon;

  useEffect(() => {
    const interval = setInterval(
      () => setRandomCasinoImageIndex(Math.floor(Math.random() * 3)),
      5000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex column homeHeaderContainerWrapper">
      <div
        className={`flex justify-between ${
          width < BREAKPOINT_XS ? 'column' : 'row'
        } homeHeaderContainer items-center gap-10 py-md`}>
        <div className="flex justify-between row items-center full-width px-lg">
          <div className="row items-center gap-40 relative">
            <img
              src={logo}
              height={40}
              alt="homeHeaderLogo"
              className="homeHeaderLogo"
              onClick={() => {
                if (getParam.get('gameFilter') !== FILTERS_TODAY_NAME) {
                  navigate(
                    `/sports?sport=Soccer&gameFilter=${FILTERS_TODAY_NAME}`,
                  );
                }
              }}
            />
            {/*{width > BREAKPOINT_XS &&*/}
            {/*  [LasVegasLogo, leGrandeCasino, palmBeachCasino].map(*/}
            {/*    (item, index) => (*/}
            {/*      <img*/}
            {/*        src={item}*/}
            {/*        key={index}*/}
            {/*        alt="randomCasinoLogo"*/}
            {/*        className="randomCasinoLogo absolute"*/}
            {/*        style={{*/}
            {/*          right: -100,*/}
            {/*          maxHeight: 65,*/}
            {/*          transition: '1s',*/}
            {/*          opacity: index !== randomCasinoImageIndex ? 0 : 1,*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    ),*/}
            {/*  )}*/}
          </div>
          {width > BREAKPOINT_XS && isLoggedIn && <DepositButton />}
          <div className="menuContainer flex row items-center gap-10 relative">
            {!isLoggedIn && <LoggedOutContainer />}

            {isLoggedIn && (
              <div
                onMouseEnter={() => setIsMainMenuDropDownOpen(true)}
                onMouseLeave={() => setIsMainMenuDropDownOpen(false)}
                ref={mainMenuDropDownContainerRef}
                onClick={e => e.stopPropagation()}
                className="row items-center gap-5 cursor-pointer">
                <div
                  // style={{
                  //   width: 170,
                  //   height: 30,
                  //   background: isMainMenuDropDownOpen
                  //       ? 'var(--appYellow)'
                  //       : '#003069',
                  // }}
                  // className={`flex items-center gap-18 mainMenu justify-between cursor-pointer ${
                  //   isMainMenuDropDownOpen
                  //     ? 'menuOpened rounded-top-borders'
                  //     : 'menuClosed rounded-borders'
                  // }`}

                  // onClick={() => {
                  //   setIsMainMenuDropDownOpen(prevState => !prevState);
                  // }}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    boxShadow: `0px 0px 15px -2px ${
                      user?.is_verified ? 'var(--appYellow)' : 'var(--appRed)'
                    }`,
                  }}
                  className="row gap-5 whiteBackground justify-center items-center">
                  <span className="bold-600 font-13 blueText text-caption-small">
                    {user?.first_name?.charAt(0)?.toUpperCase()}
                  </span>
                </div>
                {/*<ArrowIcon width={8} height={8} fill="var(--white)" />*/}
                {/*<div*/}
                {/*  style={{*/}
                {/*    width: 170,*/}
                {/*    height: 30,*/}
                {/*    background: isMainMenuDropDownOpen*/}
                {/*      ? 'var(--appYellow)'*/}
                {/*      : '#003069',*/}
                {/*  }}*/}
                {/*  className={`flex items-center gap-18 mainMenu justify-between cursor-pointer ${*/}
                {/*    isMainMenuDropDownOpen*/}
                {/*      ? 'menuOpened rounded-top-borders'*/}
                {/*      : 'menuClosed rounded-borders'*/}
                {/*  }`}*/}
                {/*  onClick={() => {*/}
                {/*    setIsMainMenuDropDownOpen(prevState => !prevState);*/}
                {/*  }}>*/}
                {/*  /!*<UserIcon width={18} height={18} fill="var(--white)" />*!/*/}
                {/*  /!*<span*!/*/}
                {/*  /!*  className={`font-12 bold-600 text-uppercase ${*!/*/}
                {/*  /!*    isMainMenuDropDownOpen ? 'blueText' : 'whiteText'*!/*/}
                {/*  /!*  }`}>*!/*/}
                {/*  /!*  {user?.first_name}*!/*/}
                {/*  /!*</span>*!/*/}
                {/*  /!*<div*!/*/}
                {/*  /!*  style={{width: 18, height: 18, borderRadius: '50%'}}*!/*/}
                {/*  /!*  className={`${*!/*/}
                {/*  /!*    isMainMenuDropDownOpen*!/*/}
                {/*  /!*      ? 'darkBlueBackground'*!/*/}
                {/*  /!*      : 'whiteBackground'*!/*/}
                {/*  /!*  } row items-center justify-center`}>*!/*/}
                {/*  /!*  <ArrowIcon*!/*/}
                {/*  /!*    width={8}*!/*/}
                {/*  /!*    height={8}*!/*/}
                {/*  /!*    fill={*!/*/}
                {/*  /!*      isMainMenuDropDownOpen ? 'white' : 'var(--darkBlue)'*!/*/}
                {/*  /!*    }*!/*/}
                {/*  /!*  />*!/*/}
                {/*  /!*</div>*!/*/}
                {/*</div>*/}
                {isMainMenuDropDownOpen && (
                  <MenuDropDown
                    contentRef={mainMenuDropDownContainerRef}
                    setIsMainMenuDropDownOpen={setIsMainMenuDropDownOpen}
                  />
                )}
              </div>
            )}
            <div className="row items-center gap-10">
              <AppLanguageChooser type={3} />
              {showSearch && (
                <div
                  onClick={e => onSearchClick(e)}
                  style={{height: 30, width: 30, backgroundColor: '#003069'}}
                  className="cursor-pointer  flex items-center justify-center rounded-borders">
                  <SearchIcon width={12} height={12} fill="var(--white)" />
                </div>
              )}
            </div>
          </div>
        </div>
        {width < BREAKPOINT_XS && isLoggedIn && <DepositButton />}
      </div>
      {showTabs && (
        <div className="row justify-between headerFiltersContainer px-md">
          <AppButton
            type={19}
            height={30}
            width={132}
            active={getParam?.get('showPromotions')}
            className="row items-center justify-center filtersContainerFixedMargins"
            onClick={() => {
              getParam.set('showPromotions', 'all');
              setGetParam(getParam);
            }}
            title={
              <div className="flex row gap-7 items-center justify-center">
                <PromotionsIcon />
                <span className="font-12 bold-600">{t('promotions')}</span>
              </div>
            }
          />
          {/*<Swiper*/}
          {/*  freeMode*/}
          {/*  keyboard*/}
          {/*  mousewheel*/}
          {/*  spaceBetween={10}*/}
          {/*  slidesPerView="auto"*/}
          {/*  modules={[Keyboard, FreeMode]}*/}
          {/*  className="headerFiltersSwiper full-width">*/}
          {/*  {Object.values(HEADER_FILTERS).map(item => (*/}
          {/*    <SwiperSlide key={item?.path}>*/}
          {/*      <AppButton*/}
          {/*        type={6}*/}
          {/*        // width={160}*/}
          {/*        onClick={() => {*/}
          {/*          if (item?.name === HEADER_FILTERS_SPORTS) {*/}
          {/*            if (getParam.get('gameFilter') !== FILTERS_TODAY_NAME) {*/}
          {/*              navigate(item?.path);*/}
          {/*              getSports({rid: GET_ALL_SPORTS});*/}
          {/*            }*/}
          {/*          } else {*/}
          {/*            getParam.delete('provider');*/}
          {/*            getParam.delete('subCategoryId');*/}
          {/*            setGetParam(getParam);*/}
          {/*            navigate(item?.path);*/}
          {/*          }*/}
          {/*          window.scrollTo(0, 0);*/}
          {/*        }}*/}
          {/*        active={location?.pathname?.includes(item?.activeState)}*/}
          {/*        title={*/}
          {/*          <div className="flex row gap-7 items-center justify-center">*/}
          {/*            {item?.icon && (*/}
          {/*              <img*/}
          {/*                width={15}*/}
          {/*                alt={item?.name}*/}
          {/*                src={require(*/}
          {/*                  `../../Assets/Icons/HeaderFilters/${item?.icon}.png`,*/}
          {/*                )}*/}
          {/*              />*/}
          {/*            )}*/}
          {/*            <span className="font-12 bold-600 blueText">*/}
          {/*              {t(item?.name)}*/}
          {/*            </span>*/}
          {/*          </div>*/}
          {/*        }*/}
          {/*      />*/}
          {/*    </SwiperSlide>*/}
          {/*  ))}*/}
          {/*</Swiper>*/}
          <div className="row justify-center gap-35">
            {Object.values(HEADER_FILTERS).map(item => (
              <div className="headerFilterButtonContainer" key={item?.path}>
                <div
                  className={`yellowBackground activeLine ${
                    !location?.pathname?.includes(item?.activeState) &&
                    'hideActiveLine'
                  }`}></div>
                <div
                  className="row items-center gap-5 cursor-pointer filtersContainerFixedMargins"
                  onClick={() => {
                    if (item?.name === HEADER_FILTERS_SPORTS) {
                      if (getParam.get('gameFilter') !== FILTERS_TODAY_NAME) {
                        navigate(item?.path);
                        getSports({rid: GET_ALL_SPORTS});
                      }
                    } else {
                      getParam.delete('provider');
                      getParam.delete('subCategoryId');
                      setGetParam(getParam);
                      navigate(item?.path);
                    }
                    window.scrollTo(0, 0);
                  }}>
                  <img
                    width={25}
                    height={25}
                    alt={item?.name}
                    src={require(
                      `../../Assets/Icons/HeaderFilters/${item?.icon}_1${
                        location?.pathname?.includes(item?.activeState)
                          ? ''
                          : '_white'
                      }.png`,
                    )}
                  />
                  <span
                    className={`font-15 bold-500 ${
                      location?.pathname?.includes(item?.activeState)
                        ? 'yellowText'
                        : 'whiteText'
                    }`}>
                    {t(item?.name)}
                  </span>
                </div>
              </div>
              // <AppButton
              //   type={6}
              //   key={item?.path}
              //   width="fit-content"
              //   onClick={() => {
              //     if (item?.name === HEADER_FILTERS_SPORTS) {
              //       if (getParam.get('gameFilter') !== FILTERS_TODAY_NAME) {
              //         navigate(item?.path);
              //         getSports({rid: GET_ALL_SPORTS});
              //       }
              //     } else {
              //       getParam.delete('provider');
              //       getParam.delete('subCategoryId');
              //       setGetParam(getParam);
              //       navigate(item?.path);
              //     }
              //     window.scrollTo(0, 0);
              //   }}
              //   active={location?.pathname?.includes(item?.activeState)}
              //   title={
              //     <div className="flex row gap-7 items-center justify-center">
              //       {item?.icon && (
              //         <img
              //           width={15}
              //           alt={item?.name}
              //           src={require(
              //             `../../Assets/Icons/HeaderFilters/${item?.icon}.png`,
              //           )}
              //         />
              //       )}
              //       <span className="font-12 bold-600 blueText">
              //         {t(item?.name)}
              //       </span>
              //     </div>
              //   }
              // />
            ))}
          </div>
          <AppButton
            type={19}
            width={132}
            height={30}
            active={showBetSlip}
            className="row items-center justify-center filtersContainerFixedMargins"
            onClick={() => dispatch(setShowBetSlip(!showBetSlip))}
            // active={location?.pathname?.includes(item?.activeState)}
            title={
              <div className="flex row gap-7 items-center justify-center">
                <CrownIcon fill={showBetSlip ? 'var(--appBlue)' : 'white'} />
                <span className={`font-12 bold-600`}>{t('myBets')}</span>
              </div>
            }
          />
          {/*<AppDownloadDropDown />*/}
          {/*<div className="relative cursor-pointer flex items-center">*/}
          {/*  {!!betSlipCount && (*/}
          {/*    <div className="absolute items-center flex justify-center betSlipCounter">*/}
          {/*      <span className="text-caption-extra-small blueText bold-600">*/}
          {/*        {betSlipCount}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*  <BetSlipIcon*/}
          {/*    width={25}*/}
          {/*    height={25}*/}
          {/*    fill="var(--white)"*/}
          {/*    className="mx-md"*/}
          {/*    onClick={() => dispatch(setShowBetSlip(!showBetSlip))}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*{location?.pathname?.includes('/casino') && width > BREAKPOINT_XS && (*/}
          {/*  <div*/}
          {/*    onClick={() => dispatch(setShowBetSlip(!showBetSlip))}*/}
          {/*    className={`row items-center gap-5 py-sm px-md mx-sm rounded-borders cursor-pointer ${*/}
          {/*      showBetSlip ? 'yellowBackground' : 'darkBlueBackground'*/}
          {/*    }`}>*/}
          {/*    <MyBetsIcon*/}
          {/*      width={18}*/}
          {/*      height={18}*/}
          {/*      fill={showBetSlip ? 'var(--appBlue)' : 'white'}*/}
          {/*    />*/}
          {/*    <span*/}
          {/*      className={`text-caption-extra-small bold-500 flex-shrink ${*/}
          {/*        showBetSlip ? 'blueText' : 'whiteText'*/}
          {/*      }`}>*/}
          {/*      {t('myBets')}*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      )}
      {/*{!location?.pathname?.includes('/casino') && (*/}
      {/*  <div className="blueBackground my-sm rounded-borders">*/}
      {/*    <SportFilters />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{location?.pathname?.includes('/casino') && <CasinoFixedGames />}*/}
    </div>
  );
};

export default memo(Header);
