import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import './index.scss';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {AppSwitcher} from '../../../UI';

import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/starFilled.svg';

const FreeBet = ({
  item,
  setStakeAmount,
  selectedFreeBet,
  setSelectedFreeBet,
}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  // const data = useMemo(
  //   () =>
  //     freeBetData?.map(item => ({
  //       ...item,
  //       value: item?.id,
  //       name: `${item?.amount} ${currency} (${t('exp')} ${moment
  //         ?.unix(item?.expiration_date)
  //         ?.format('DD.MM.YY, HH:MM')})`,
  //     })),
  //   [currency, freeBetData, t],
  // );

  return (
    <div className="column gap-10">
      <div className="freeBetContainer pr-md paddingVertical-13 pl-xl gap-5 column rounded-borders">
        <div className="row items-center gap-10 justify-between">
          <div className="row items-center gap-10 flex-shrink">
            <StarIcon />
            <span style={{textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
              <span className="font-14 bold-700 yellowText">{`${currency} ${bigNumbersPrettier(
                item?.amount,
              )} `}</span>
              <span className="bold-600 whiteText font-14">{t('freeBet')}</span>
            </span>
          </div>
          <AppSwitcher
            type={6}
            isToggled={item?.id === selectedFreeBet?.id}
            onToggle={() => {
              setSelectedFreeBet(
                item?.id === selectedFreeBet?.id ? null : item,
              );
              setStakeAmount(
                item?.id === selectedFreeBet?.id
                  ? ''
                  : item?.amount?.toString(),
              );
            }}
          />
        </div>
        {/*<span className="whiteText font-10">{t('freeBetCondition')}</span>*/}
      </div>
      {/*{enableFreeBet && (*/}
      {/*  <AppSelect*/}
      {/*    type={1}*/}
      {/*    options={data}*/}
      {/*    disabled={freeBetData?.length === 1}*/}
      {/*    onItemClickCb={item => {*/}
      {/*      setSelectedFreeBet(item);*/}
      {/*      setStakeAmount(item?.amount?.toString());*/}
      {/*    }}*/}
      {/*    value={selectedFreeBet?.id}*/}
      {/*    titleClassName="bold-600 font-13"*/}
      {/*    itemClassName="py-md px-lg font-13"*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default memo(FreeBet);
