import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const TableTennisTitleScore = ({game}) => {
  const {t} = useTranslation();

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;

    gameHalf = gameHalf?.replace?.(/(\D)(\d)/, '$1 $2');

    return gameHalf?.trim?.()?.replace?.('notstarted', 'notStarted');
  }, [game?.info?.current_game_state]);

  return game?.info?.current_game_state === 'finished' ? (
    <span className="bold-700 font-10 orangeText">{t('finished')}</span>
  ) : (
    !!game?.is_live && game?.info?.current_game_state && (
      <span className={`bold-700 font-10 orangeText text-capitalize`}>
        {t(halfValue)}
      </span>
    )
  );
};

export default TableTennisTitleScore;
