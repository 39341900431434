import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {useDispatch, useSelector} from 'react-redux';

import './FiltersList.scss';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';
import {getPartner} from '../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as Star} from '../../../Assets/Icons/Globals/star.svg';

import {AppButton} from '../../UI';

const FiltersList = ({
  isGameLive,
  GROUP_DATA,
  sportcast_id,
  activeGroupId,
  setActiveGroupId,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const partner = useSelector(getPartner);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const betBuilderIdParam = useMemo(
    () => getParam.get('betBuilderId'),
    [getParam],
  );

  return (
    <div
      className={`filtersListContainer blueBackground z-index-1 ${
        partner?.bet_builder_enabled && sportcast_id
          ? 'row items-center gap-5'
          : ''
      }`}>
      {partner?.bet_builder_enabled && sportcast_id && !isGameLive && (
        <AppButton
          type={19}
          height={30}
          width="auto"
          active={!!betBuilderIdParam}
          className="flex items-center gap-5 py-xs"
          onClick={() => {
            if (!isLoggedIn) {
              dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
            } else {
              if (!!sportcast_id) {
                if (!!betBuilderIdParam) {
                  getParam.delete('betBuilderId');
                } else {
                  getParam.set('betBuilderId', sportcast_id);
                }
                setGetParam(getParam);
              }
            }
          }}
          title={
            <div
              style={{width: 120}}
              className="flex row justify-center textCenter filterItemContainer gap-5 py-sm px-lg">
              <span className="text-caption bold-600 text-uppercase">
                {t('betBuilder')}
              </span>
            </div>
          }
        />
      )}
      <Swiper
        freeMode
        slidesPerGroup={1}
        slidesPerView="auto"
        keyboard={width > BREAKPOINT_XS}
        mousewheel={width > BREAKPOINT_XS}
        // navigation={width > BREAKPOINT_XS}
        style={{marginLeft: 0, marginRight: 0}}
        spaceBetween={width > BREAKPOINT_XS ? 10 : 7}
        slidesOffsetAfter={width > BREAKPOINT_XS ? 0 : 0}
        slidesOffsetBefore={width > BREAKPOINT_XS ? 0 : 0}
        className="filtersListSwiper flex items-center mt-sm overflow-hidden"
        modules={[
          FreeMode,
          ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
        ]}>
        {GROUP_DATA?.map(item => (
          <SwiperSlide key={item?.title}>
            <AppButton
              type={19}
              height={30}
              className="flex items-center gap-5 py-xs"
              active={item?.groupId === activeGroupId && !betBuilderIdParam}
              onClick={() => {
                getParam.delete('betBuilderId');
                setGetParam(getParam);
                setActiveGroupId(item?.groupId);
              }}
              title={
                item?.icon ? (
                  <div className="px-md items-center justify-center row">
                    <Star width={25} height={25} fill="var(--white)" />
                  </div>
                ) : (
                  <div className="flex row justify-center textCenter filterItemContainer gap-5 py-sm px-lg">
                    <span className="text-caption bold-600">
                      {item?.title?.toUpperCase()}
                    </span>
                    {/*<span className="text-caption bold-600">*/}
                    {/*  ({item?.data?.length})*/}
                    {/*</span>*/}
                  </div>
                )
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(FiltersList);
