import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const TennisTitleScore = ({game}) => {
  const {t} = useTranslation();

  const setValues = game?.stats?.[`score_${game?.info?.current_game_state}`];

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;

    // Create a mapping for special cases
    const ordinals = {
      1: '1st',
      2: '2nd',
      3: '3rd',
    };

    // Use regex to find and replace the "set" part and extract the number
    return gameHalf?.replace?.(/set(\d+)/g, (match, number) => {
      const num = parseInt(number, 10);
      if (ordinals[num]) {
        return ordinals[num]; // If the number is in the special case, return its ordinal
      }
      // Default case for 4th, 5th, 6th, etc.
      return `${num}th`;
    });
  }, [game?.info?.current_game_state]);

  return game?.info?.current_game_state === 'finished' ? (
    <span className="bold-700 font-10 orangeText">{t('finished')}</span>
  ) : (
    !!game?.is_live && game?.info?.current_game_state && (
      <div className="flex gap-5 rowCenter">
        <span className={`bold-700 font-10 orangeText`}>
          {halfValue}
          <span className="bold-400 blueText ml-xs">{t('Set')}</span>
        </span>
        <div className="font-10 orangeText bold-600">
          <span className="">{setValues?.team1_value}</span>
          <span className=""> - </span>
          <span className="">{setValues?.team2_value}</span>
        </div>
      </div>
    )
  );
};

export default TennisTitleScore;
