import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {
  convertTeamNameToId,
  formatEventName,
  formatGameStates,
} from '../../../../Utils/EventNamesFormatter';
import {getDateColor} from '../../../../Utils/DateFormatter';
import {getLast30Days} from '../../../../Utils/GetDatePeriods';
import {getGameStartTime} from '../../../../Utils/TimeConvertors';

import {
  GET_MATCH_SCORES,
  GET_SPECIFIC_GAME_STATS,
} from '../../../../Constants/Socket';
import {SportIcons} from '../../../../Constants/SportIcons';

import EventItem from '../../../Home/Games/EventItem';

import {AppButton, AppLiveIndicator} from '../../../UI';
import MatchResultInfoSkeleton from '../../../UI/Skeletons/MatchResultInfoSkeleton';
import BetItemInfoSkeleton from '../../../UI/Skeletons/BetItemInfoSkeleton';
import Circles from '../Circles';
import Scoring from '../../../Scoring';

const BetHistoryItem = ({
  gameId,
  isLast,
  team1Name,
  team2Name,
  coeficient,
  sportAlias,
  betOutCome,
  event_name,
  market_name,
  eventOutCome,
  game_start_date,
  setIsBetHistoryDataLoading,
}) => {
  const {
    getMatchScores,
    getBetsHistory,
    getOpenedBetsCount,
    getSpecificGameStats,
    unSubscribeLiveEvents,
    subscribeToCashOutAmount,
  } = useSocket();
  const {t} = useTranslation();

  const team1IdRef = useRef(null);
  const team2IdRef = useRef(null);

  const wssSocket = useSelector(getWssSocket);

  const [subId, setSubId] = useState(null);
  const [isLive, setIsLive] = useState(null);
  const [passTeam, setPassTeam] = useState(null);
  const [setCount, setSetCount] = useState(null);
  const [team1Score, setTeam1Score] = useState(0);
  const [team2Score, setTeam2Score] = useState(0);
  const [currentGame, setCurrentGame] = useState(null);
  const [statsPasses, setStatsPasses] = useState(null);
  const [matchResult, setMatchResult] = useState(null);
  const [isMatchResultDataLoading, setIsMatchResultDataLoading] =
    useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [currentGameTime, setCurrentGameTime] = useState(null);
  const [currentGameState, setCurrentGameState] = useState(null);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(null);

  const scoringData = useMemo(
    () => ({
      ...currentGame,
      sport_alias: currentGame?.sport_alias,
      info: {
        ...(currentGame?.info || {}),
        score1: team1Score,
        score2: team2Score,
        pass_team: passTeam,
        current_game_state: currentGameState,
      },
      stats: {
        ...(currentGame?.stats || {}),
        passes: statsPasses,
        [`score_${currentGameState}`]: currentGameLastSet,
      },
    }),
    [
      passTeam,
      team1Score,
      team2Score,
      statsPasses,
      currentGameState,
      currentGameLastSet,
    ],
  );

  const eventOutComeTitle = useMemo(
    () =>
      eventOutCome === 1 ? 'lost' : eventOutCome === 3 ? 'won' : 'returned',
    [eventOutCome],
  );

  const eventOutComeIcon = useMemo(
    () =>
      eventOutCome === 3
        ? 'won'
        : eventOutCome === 1
        ? 'lost'
        : eventOutCome === 2
        ? 'won'
        : null,
    [eventOutCome],
  );

  const betOutComeType = useMemo(
    () =>
      eventOutCome &&
      (eventOutCome === 1
        ? 5
        : eventOutCome === 2
        ? 13
        : eventOutCome === 3
        ? 3
        : 4),
    [eventOutCome],
  );

  useEffect(() => {
    if (gameId && eventOutCome === 0) {
      setIsDataLoading(true);
      getSpecificGameStats({
        gameId,
        rid: `${GET_SPECIFIC_GAME_STATS}_${gameId}`,
      });
    }
  }, [eventOutCome, gameId, getSpecificGameStats]);

  useEffect(
    () => () => subId && unSubscribeLiveEvents({subId}),
    [subId, unSubscribeLiveEvents],
  );

  useEffect(() => {
    if (gameId) {
      setIsMatchResultDataLoading(true);
      getMatchScores({
        rid: `${GET_MATCH_SCORES}_${gameId}`,
        match_id_list: [gameId],
      });
    }
  }, [gameId, getMatchScores]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case `${GET_SPECIFIC_GAME_STATS}_${gameId}`:
          if (data?.data?.subid) {
            setSubId(data?.data?.subid);
          }
          const currentGame = data?.data?.data?.game?.[gameId];
          setCurrentGame(currentGame);
          team1IdRef.current = currentGame?.team1_id;
          team2IdRef.current = currentGame?.team2_id;
          if (
            currentGame?.info?.score1 &&
            currentGame?.info?.current_game_state !== 'notstarted'
          ) {
            setTeam1Score(currentGame?.info?.score1);
            setTeam2Score(currentGame?.info?.score2);
          }
          if (typeof currentGame?.is_live !== 'undefined') {
            setIsLive(!!currentGame?.is_live);
          }
          if (typeof currentGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(currentGame?.info?.current_game_state);
          }
          if (
            typeof currentGame?.stats?.[
              `score_${currentGame?.info?.current_game_state}`
            ] !== 'undefined'
          ) {
            setCurrentGameLastSet(
              currentGame?.stats?.[
                `score_${currentGame?.info?.current_game_state}`
              ],
            );
          }
          if (typeof currentGame?.info?.current_game_time !== 'undefined') {
            setCurrentGameTime(currentGame?.info?.current_game_time);
          }

          if (typeof currentGame?.info?.set_count !== 'undefined') {
            setSetCount(currentGame?.info?.set_count);
          }
          if (typeof currentGame?.stats?.passes !== 'undefined') {
            setStatsPasses(prevState => ({
              ...prevState,
              ...currentGame?.stats?.passes,
            }));
          }
          if (
            typeof currentGame?.info?.pass_team !== 'undefined' &&
            !!currentGame?.info?.pass_team
          ) {
            setPassTeam(currentGame?.info?.pass_team);
          }
          setIsDataLoading(false);
          break;
        case `${GET_MATCH_SCORES}_${gameId}`:
          const score = data?.data?.details?.[0]?.score;
          if (score !== 'Void') {
            const index = score?.indexOf('(') || score?.indexOf(',');
            setMatchResult(score?.slice(0, index)?.trim?.()?.replace(',', ''));
          }
          setIsMatchResultDataLoading(false);
          break;
        default:
          break;
      }
      if (data?.data?.[subId]) {
        const changedGame = data?.data?.[subId]?.game?.[gameId];
        if (changedGame) {
          if (typeof changedGame?.info?.score1 !== 'undefined') {
            setTeam1Score(changedGame?.info?.score1);
          }
          if (typeof changedGame?.info?.score2 !== 'undefined') {
            setTeam2Score(changedGame?.info?.score2);
          }
          if (typeof changedGame?.info?.current_game_time !== 'undefined') {
            setCurrentGameTime(changedGame?.info?.current_game_time);
          }
          if (typeof changedGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(changedGame?.info?.current_game_state);
          }
          if (
            typeof changedGame?.stats?.[`score_${currentGameState}`] !==
            'undefined'
          ) {
            setCurrentGameLastSet(prevState => ({
              ...prevState,
              ...changedGame?.stats?.[`score_${currentGameState}`],
            }));
          }
          if (typeof changedGame?.stats?.passes !== 'undefined') {
            setStatsPasses(prevState => ({
              ...prevState,
              ...changedGame?.stats?.passes,
            }));
          }
          if (
            typeof changedGame?.info?.pass_team !== 'undefined' &&
            !!changedGame?.info?.pass_team
          ) {
            setPassTeam(changedGame?.info?.pass_team);
          }
        } else {
          setTimeout(() => {
            setIsBetHistoryDataLoading(true);
            setIsMatchResultDataLoading(true);
            subscribeToCashOutAmount();
            getBetsHistory({
              outcome: 0,
              to_date: getLast30Days()?.end_date,
              from_date: getLast30Days()?.start_date,
            });
            getOpenedBetsCount({
              to_date: getLast30Days()?.end_date,
              from_date: getLast30Days()?.start_date,
            });
            getMatchScores({
              rid: `${GET_MATCH_SCORES}_${gameId}`,
              match_id_list: [gameId],
            });
          }, 1000);
        }
      }
    },
    [
      subId,
      gameId,
      getMatchScores,
      getBetsHistory,
      currentGameState,
      getOpenedBetsCount,
      subscribeToCashOutAmount,
      setIsBetHistoryDataLoading,
    ],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="betHistoryItemContainer">
      <div
        style={{background: '#E6EAEE'}}
        className="gameInfoContainer column gap-5 full-width py-sm px-md">
        <div className="row items-center  gap-10 flex-shrink">
          <div className="row items-center gap-5">
            {isLive && <AppLiveIndicator />}
          </div>
          {isLive ? (
            <div className="row items-center gap-5">
              <Scoring isTitleScore game={scoringData} />
            </div>
          ) : (
            <span className="blueText font-10 text-capitalize">
              {getGameStartTime(game_start_date).toLowerCase()}
            </span>
          )}
        </div>
        <div className="row items-center gap-5 teamNamesContainer full-width overflow-hidden">
          {!!sportAlias && (
            <img
              width={25}
              alt={sportAlias}
              className="flex-shrink"
              src={SportIcons?.[sportAlias]}
            />
          )}
          <div className="column overflow-hidden full-width">
            <div className="row items-center gap-5">
              <img
                width={15}
                height={15}
                alt={team1IdRef.current}
                src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                  team1IdRef.current / 2000,
                )}/${team1IdRef.current}.png`}
              />
              <span
                className={`ellipsis font-12 bold-${
                  !!matchResult?.split(':')?.[0] &&
                  !!matchResult?.split(':')?.[1]
                    ? +matchResult?.split(':')?.[0] >=
                      +matchResult?.split(':')?.[1]
                      ? '700'
                      : '500'
                    : +team1Score >= +team2Score
                    ? '700'
                    : '500'
                } blueText full-width`}>
                {team1Name}
              </span>
              {/*{isLive && !!team1Score && typeof team1Score !== 'undefined' && (*/}
              {/*  <span className="orangeText font-12 bold-700 full-width textRight">*/}
              {/*    {team1Score}*/}
              {/*  </span>*/}
              {/*)}*/}
            </div>

            {!!team2Name && (
              <div className="row items-center gap-5 full-width">
                <img
                  width={15}
                  height={15}
                  alt={team2IdRef.current}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    team2IdRef.current / 2000,
                  )}/${team2IdRef.current}.png`}
                />
                <span
                  className={`ellipsis font-12 bold-${
                    !!matchResult?.split(':')?.[1] &&
                    !!matchResult?.split(':')?.[0]
                      ? +matchResult?.split(':')?.[1] >=
                        +matchResult?.split(':')?.[0]
                        ? '700'
                        : '500'
                      : +team2Score >= +team1Score
                      ? '700'
                      : '500'
                  } blueText full-width`}>
                  {team2Name}
                </span>
                {/*{isLive && !!team2Score && typeof team2Score !== 'undefined' && (*/}
                {/*  <span className="orangeText font-12 bold-700 full-width textRight">*/}
                {/*    {team2Score}*/}
                {/*  </span>*/}
                {/*)}*/}
              </div>
            )}
          </div>
          <div className="column items-center">
            {isLive && <Scoring game={scoringData} />}
          </div>
          <div className="row items-center gap-5">
            {isMatchResultDataLoading ? (
              <MatchResultInfoSkeleton />
            ) : (
              matchResult &&
              !isLive && (
                <span className="bold-600 text-caption text-right blueText ellipsis">
                  {matchResult}
                </span>
              )
            )}
            {betOutComeType ? (
              <div>
                <AppButton
                  fontSize={11}
                  disabledWithoutLayer
                  type={betOutComeType}
                  titleClassName="bold-400"
                  title={t(eventOutComeTitle)}
                  className="justify-center items-center row px-sm py-xxs"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {isDataLoading ? (
          <BetItemInfoSkeleton />
        ) : (
          // <div className="column items-end justify-center flex-shrink">
          //   <div className="row items-center gap-5">
          //     {isLive && <AppLiveIndicator />}
          //   </div>
          //   {isLive ? (
          //     <div className="row items-center gap-5 text-caption">
          //       {isLive &&
          //         !!team1Score &&
          //         !!team2Score &&
          //         typeof team1Score !== 'undefined' &&
          //         typeof team2Score !== 'undefined' && (
          //           <div className="text-caption bold-600 row items-center">
          //             <span className="orangeText">{team1Score}</span>
          //             <span className="orangeText">-</span>
          //             <span className="orangeText">{team2Score}</span>
          //           </div>
          //         )}
          //       {setCount !== 1 &&
          //         !currentGameTime &&
          //         typeof currentGameLastSet?.team1_value !== 'undefined' && (
          //           <span className="orangeText bold-600">
          //             {currentGameLastSet?.team1_value}-
          //             {currentGameLastSet?.team2_value}
          //           </span>
          //         )}
          //       {currentGameState &&
          //         typeof currentGameState !== 'undefined' && (
          //           <span className="grayText">
          //             {t(
          //               formatGameStates({
          //                 sportAlias,
          //                 gameState: currentGameState,
          //               }),
          //             )}
          //           </span>
          //         )}
          //       {currentGameTime && currentGameTime !== 'undefined' && (
          //         <span className="grayText">{currentGameTime?.trim?.()}`</span>
          //       )}
          //     </div>
          //   ) : eventOutComeTitle ? (
          //     <div>
          //       <AppButton
          //         fontSize={10}
          //         disabledWithoutLayer
          //         type={betOutComeType}
          //         className="px-md py-sm"
          //         title={t(eventOutComeTitle)}
          //       />
          //     </div>
          //   ) : (
          //     <span className="blueText font-10 text-capitalize">
          //       {getGameStartTime(game_start_date).toLowerCase()}
          //     </span>
          //   )}
          //   {isMatchResultDataLoading ? (
          //     <MatchResultInfoSkeleton />
          //   ) : (
          //     matchResult &&
          //     !isLive && (
          //       <span className="bold-600 text-caption text-right blueText ellipsis">
          //         {matchResult}
          //       </span>
          //     )
          //   )}
          // </div>
          <></>
        )}
      </div>
      <div
        className={`eventInfoContainer whiteBackground column pa-md ${
          isLast ? '' : ''
        } ${!isLast ? 'dashed' : ''}`}>
        <div className="row items-end justify-between gap-10">
          <div className="column overflow-hidden">
            <span className="font-10 blueText">
              {formatEventName({
                team1Name,
                team2Name,
                name: market_name,
              })}
            </span>
            <div
              style={{lineHeight: 1.3}}
              className="row items-center gap-5 overflow-hidden">
              {!isNaN(
                convertTeamNameToId({
                  name: event_name,
                  team2Id: team2IdRef.current,
                  team1Id: team1IdRef.current,
                }),
              ) && (
                <img
                  width={15}
                  height={15}
                  alt="resultTeamLogo"
                  style={{objectFit: 'contain'}}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    convertTeamNameToId({
                      name: event_name,
                      team2Id: team2IdRef.current,
                      team1Id: team1IdRef.current,
                    }) / 2000,
                  )}/${convertTeamNameToId({
                    name: event_name,
                    team2Id: team2IdRef.current,
                    team1Id: team1IdRef.current,
                  })}.png`}
                />
              )}

              <span className="font-12 bold-700 blueText ellipsis-2-lines">
                {formatEventName({
                  team1Name,
                  team2Name,
                  name: event_name,
                })}
              </span>
              {eventOutComeIcon && !!matchResult && (
                <img
                  width={20}
                  alt={eventOutComeIcon}
                  src={require(
                    `../../../../Assets/Icons/Globals/${eventOutComeIcon}.svg`,
                  )}
                />
              )}
            </div>
          </div>
          <div className="flex gap-5">
            <EventItem betSlip type={1} price={coeficient} disabled />
          </div>
        </div>
      </div>
      {!isLast && <Circles />}
    </div>
  );
};

export default memo(BetHistoryItem);
